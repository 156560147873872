import axios from "../../lib/axiosInstance";

export function me(payload) {
  return axios.get("users/me", payload).then((res) => res.data);
}

export function fetchNearbyFreelancers(payload) {
  const queryParams = new URLSearchParams();

  // Include only non-empty filters in the query parameters
  if (payload.latitude) {
    queryParams.append("latitude", payload.latitude);
  }
  if (payload.longitude) {
    queryParams.append("longitude", payload.longitude);
  }
  if (payload.radius) {
    queryParams.append("radius", payload.radius);
  }

  return axios
    .get("users/nearby", { params: queryParams })
    .then((res) => res.data);
}

export function fetchUserDetails(id) {
  return axios.get(`users/${id}`).then((res) => res.data);
}

export function fetchUserQualifications() {
  return axios.get("users/qualifications").then((res) => res.data);
}

export function addQualificationsToUser(payload) {
  return axios.post("users/qualifications", payload).then((res) => res.data);
}
