import React from "react";
import { useSelector } from "react-redux";

const HomePage = () => {
  const user = useSelector((state) => state.authUser.value);

  return (
    <div>
      <h1>Profile:</h1>
      <p>First Name: {user.user.firstName}</p>
      <p>Last Name: {user.user.lastName}</p>
    </div>
  );
};

export default HomePage;
