import React from "react";
import BaseCard from "../../../components/ui/BaseCard";
import BaseButton from "../../../components/ui/BaseButton";

const Contacts = ({ contacts, workOrderManager }) => {
  return (
    <BaseCard className="pt-4 pb-6 px-16 mt-2 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4 flex items-center pb-1">
        <h6 className="text-dark">Contacts</h6>
      </div>
      <div className="grid grid-cols-2 gap-12 mt-6">
        {workOrderManager && (
          <div className="bg-light pt-3 pb-6 px-4 rounded-lg">
            <p className="text-lg font-normal mb-1">Work Order Manager</p>
            <p className="mb-1">{workOrderManager?.firstName}</p>
            <p className="text-primary mb-1">{workOrderManager?.phoneNumber}</p>
            <p className="text-primary mb-1">{workOrderManager?.email}</p>
          </div>
        )}
        {contacts?.map((contact, index) => (
          <div className="bg-light pt-3 pb-6 px-4 rounded-lg" key={index}>
            <p className="text-lg font-normal mb-1">{contact?.position}</p>
            <p className="mb-1">{contact?.name}</p>
            <p className="text-primary mb-1">{contact?.phoneNumber}</p>
            <p className="text-primary mb-1">{contact?.email}</p>
            {contact?.note && (
              <p className="mb-1 text-sm">Note: {contact?.note}</p>
            )}
          </div>
        ))}
      </div>
      <BaseButton
        className="pt-1.5 pb-1.5 text-primary bg-[#EFF4FF] hover:text-white hover:bg-primary mt-8"
        fullWidth={false}
      >
        Add Contact
      </BaseButton>
    </BaseCard>
  );
};

export default Contacts;
