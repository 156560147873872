import Backdrop from "@mui/material/Backdrop";

import React from "react";
import { Hourglass } from "react-loader-spinner";
import { brandSecondaryColor } from "../../../utils/variables";

const BaseLoader = ({ isLoading, className, zIndex = 1 }) => {
  return (
    <Backdrop
      className="bg-white/[0.6]"
      sx={(theme) => ({ zIndex: theme.zIndex.drawer + zIndex })}
      open={isLoading}
    >
      <Hourglass
        visible={true}
        height="50"
        width="50"
        ariaLabel="hourglass-loading"
        colors={[brandSecondaryColor, "#ff9b60"]}
      />
    </Backdrop>
  );
};

export default BaseLoader;
