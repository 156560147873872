import React, { useRef } from "react";
import ReactQuill from "react-quill";
import "./style.css";

const BaseEditor = ({ value, onChange, className, readOnly }) => {
  const quillRef = useRef(null);

  const options = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  };

  const handleDoubleClick = () => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor(); // Access the Quill editor instance
      const contentLength = editor.getLength(); // Get the total content length
      editor.setSelection(0, contentLength); // Select all text
    }
  };
  return (
    <div onDoubleClick={handleDoubleClick}>
      <ReactQuill
        ref={quillRef}
        readOnly={readOnly}
        className={className}
        theme="snow"
        value={value}
        onChange={onChange}
        modules={!readOnly && options}
      />
    </div>
  );
};

export default BaseEditor;
