import React from "react";

const CurrentStatusCard = ({ status, tagline }) => {
  return (
    <div className="shadow-md rounded-md py-3 px-4 h-full min-h-24  w-full">
      <div className="flex">
        <i className="fa-solid fa-circle-info text-primary text-3xl"></i>
        {/* <i className="fa-regular fa-clock text-primary text-3xl"></i> */}
        <div className="ml-2 mt-2">
          <p className="font-normal text-dark text-sm">Status: {status}</p>

          <p className="text-primary mt-1">{tagline}</p>
        </div>
      </div>
    </div>
  );
};

export default CurrentStatusCard;
