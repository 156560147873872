import React, { useRef } from "react";
import { StandaloneSearchBox } from "@react-google-maps/api";
import BaseTextField from "../ui/BaseTextField";
import GoogleMapsProvider from "../GoogleMapsProvider";

function SearchLocation({
  onPlaceSelected,
  label = "Search Address",
  isDefaultField = false,
  labelShrink = false,
  value,
  ...props
}) {
  const inputRef = useRef(null);

  // Load the Google Maps JavaScript API
  /* const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY, // Your API key here
    libraries: LIBRARIES,
  }); */

  // Fetch the location's time zone from Google API
  const fetchLocationTimeZone = async (longitude, latitude) => {
    const timestamp = Math.floor(Date.now() / 1000); // Current timestamp in seconds
    const timeZoneUrl = `https://maps.googleapis.com/maps/api/timezone/json?location=${latitude},${longitude}&timestamp=${timestamp}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;

    try {
      const response = await fetch(timeZoneUrl);
      const timeZoneData = await response.json();

      return {
        timeZone: timeZoneData.timeZoneId,
        timeZoneName: timeZoneData.timeZoneName,
      };
    } catch (error) {
      console.error("Error fetching time zone data:", error);
      return {};
    }
  };

  // Handle the place selection and extract details
  const handleOnPlacesChanged = async () => {
    if (inputRef.current && inputRef.current.getPlaces) {
      const places = inputRef.current.getPlaces();

      if (places && places.length > 0) {
        const place = places[0];
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();

        const addressComponents = place?.address_components?.reduce(
          (acc, component) => {
            const type = component.types[0];
            if (type === "postal_code") acc.zipCode = component.long_name;
            if (type === "country") acc.country = component.long_name;
            if (type === "locality" || type === "sublocality")
              acc.city = component.long_name;
            if (type === "administrative_area_level_1")
              acc.state = component.long_name;
            if (type === "administrative_area_level_2")
              acc.area = component.long_name;
            return acc;
          },
          {}
        );

        const timeZone = await fetchLocationTimeZone(longitude, latitude);

        const location = {
          place,
          completeAddress: place.formatted_address,
          latitude,
          longitude,
          ...timeZone,
          ...addressComponents,
        };

        if (onPlaceSelected) {
          onPlaceSelected(location);
        }
      }
    }
  };

  return (
    <StandaloneSearchBox
      onLoad={(ref) => (inputRef.current = ref)}
      onPlacesChanged={handleOnPlacesChanged}
      onClick={(e) => e.stopPropagation()}
    >
      <BaseTextField
        fullWidth
        fullHeight={false}
        label={label}
        placeholder=""
        optional={false}
        value={value}
        labelShrink={labelShrink}
        {...props}
        isDefaultField={isDefaultField}
      />
    </StandaloneSearchBox>
  );
}

export default React.memo(SearchLocation);
