import React, { useState } from "react";
import { useMutation } from "@tanstack/react-query";

import BaseCard from "../../../components/ui/BaseCard";
import BaseTextField from "../../../components/ui/BaseTextField";
import { createWorkOrder, signup } from "../../../services/api";
import { useDispatch, useSelector } from "react-redux";

import BaseRadio from "../../../components/ui/BaseRadio";
import { RadioGroup } from "@mui/material";
import HourlyPay from "./PayFormTypes/HourlyPay";
import FullDayPay from "./PayFormTypes/FullDayPay";
import HalfDayPay from "./PayFormTypes/HalfDayPay";
import BlendedPay from "./PayFormTypes/BlendedPay";
import FixedPay from "./PayFormTypes/FixedPay";
import {
  addExpense,
  removeExpense,
  updateExpense,
  updateWorkOrderForm,
} from "../../../store/workOrder/workOrderSlice";
import BaseSelect from "../../../components/ui/BaseSelect";

const PayForm = () => {
  const dispatch = useDispatch();
  const payment = useSelector((state) => state.workOrder.payment);

  const handleChange = (event) => {
    const { name, value } = event.target;
    dispatch(
      updateWorkOrderForm({
        step: "payment",
        data: {
          pay: {
            [name]: value,
          },
        },
      })
    );
  };

  const handleExpenseChange = (index, event) => {
    const { name, value } = event.target;
    dispatch(updateExpense({ index, field: name, value }));
  };
  const handleAddExpense = () => {
    dispatch(addExpense());
  };

  const handleRemoveExpense = (id) => {
    dispatch(removeExpense(id));
  };

  return (
    <BaseCard className="pt-4 pb-6 px-16 mt-10 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4">
        <h6 className="mb-2 text-dark">Pay</h6>
      </div>

      <div className="flex w-full items-center mb-10 mt-5">
        <p className="font-normal">Payment terms</p>
        <div className="w-52 ml-6 ">
          <BaseSelect
            fullHeight={false}
            name="paymentTerms"
            onChange={handleChange}
            options={[
              {
                label: "10 Days",
                value: "10 days",
              },
              {
                label: "15 Days",
                value: "15 days",
              },
              {
                label: "20 Days",
                value: "20 days",
              },
            ]}
            defaultValue={payment.pay.paymentTerms}
          />
        </div>
        <p className="ml-6 w-7/12">
          Payment for this work order will be processed 10 days after the job is
          completed. If there are any issues related to the work, you will be
          notified within 7 days.
        </p>
      </div>
      <div>
        <div className="flex items-center">
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="HOURLY"
            name="radio-buttons-group"
          >
            <div>
              <BaseRadio
                label="Hourly"
                value="HOURLY"
                name="paymentType"
                onChange={handleChange}
              />

              <BaseRadio
                label="Full Day"
                value="FULL_DAY"
                name="paymentType"
                onChange={handleChange}
              />

              <BaseRadio
                label="Half Day"
                value="HALF_DAY"
                name="paymentType"
                onChange={handleChange}
              />
              <BaseRadio
                label="Fixed"
                value="FIXED"
                name="paymentType"
                onChange={handleChange}
              />

              <BaseRadio
                label="Blended"
                value="BLENDED"
                name="paymentType"
                onChange={handleChange}
              />
            </div>
          </RadioGroup>

          <div className="bg-[#F0F0F0] py-1 px-4 rounded-[3px] border border-[#ABABAB] ml-2 flex items-center cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11"
              height="11"
              viewBox="0 0 11 11"
              fill="none"
            >
              <path
                d="M5.80423 2.43961C6.87223 2.43961 7.26823 2.94961 7.30423 3.69961H8.63024C8.58823 2.66761 7.95823 1.71961 6.70424 1.41361V0.0996094H4.90423V1.39561C4.67023 1.44361 4.45423 1.52161 4.24423 1.61161L5.15023 2.51761C5.34223 2.46961 5.56423 2.43961 5.80423 2.43961ZM1.58623 0.651609L0.740234 1.49761L2.80423 3.56161C2.80423 4.80961 3.74023 5.49361 5.15023 5.90761L7.25624 8.01361C7.05224 8.30761 6.62624 8.55961 5.80423 8.55961C4.56823 8.55961 4.08223 8.00761 4.01623 7.29961H2.69623C2.76823 8.61361 3.75223 9.35161 4.90423 9.59761V10.8996H6.70424V9.60961C7.28023 9.50161 7.80224 9.27961 8.18023 8.93761L9.51223 10.2696L10.3582 9.42361L1.58623 0.651609Z"
                fill="#636B88"
              />
            </svg>
            <p className="text-sm ml-1">Rate comparison unavailable</p>
          </div>
        </div>

        {payment.pay.paymentType === "HOURLY" && <HourlyPay />}
        {payment.pay.paymentType === "FULL_DAY" && <FullDayPay />}
        {payment.pay.paymentType === "HALF_DAY" && <HalfDayPay />}
        {payment.pay.paymentType === "FIXED" && <FixedPay />}
        {payment.pay.paymentType === "BLENDED" && <BlendedPay />}
      </div>

      <div>
        {payment.expenses?.map((expense, index) => (
          <div className="bg-light rounded-lg pt-4 pb-8 px-10 mt-10">
            <div className="flex justify-end">
              <i
                className="fa-solid fa-trash-can cursor-pointer"
                onClick={() => handleRemoveExpense(expense?.id)}
              ></i>
            </div>
            <div className="grid grid-cols-2">
              <div className="mr-10">
                <BaseSelect
                  fullHeight={false}
                  label="Expense"
                  name="expenseType"
                  labelShrink={false}
                  required={true}
                  isDefaultField={false}
                  onChange={(e) => handleExpenseChange(index, e)}
                  options={[
                    { label: "Travel Expense", value: "TRAVEL" },
                    { label: "Tools Expense", value: "TOOLS" },
                    { label: "Material Expense", value: "MATERIAL" },
                    { label: "All Options", value: "ALL" },
                  ]}
                  defaultValue={expense.expenseType}
                  value={expense.expenseType}
                />
              </div>
              <div>
                <BaseTextField
                  fullHeight={false}
                  label="Description"
                  name="description"
                  labelShrink={false}
                  required={true}
                  isDefaultField={false}
                  optional={false}
                  value={expense.description}
                  onChange={(e) => handleExpenseChange(index, e)}
                />
              </div>
              <div className="mr-10 mt-4">
                <BaseTextField
                  fullHeight={false}
                  label="Amount"
                  name="amount"
                  labelShrink={false}
                  required={true}
                  optional={false}
                  isDefaultField={false}
                  value={expense.amount}
                  onChange={(e) => handleExpenseChange(index, e)}
                />
              </div>
            </div>
          </div>
        ))}
        <div className="inline-block cursor-pointer" onClick={handleAddExpense}>
          <div className="flex items-center mt-4 ">
            <i className="fa-solid fa-plus text-secondary"></i>
            <p className="text-secondary text-sm ml-1">Add Expenses</p>
          </div>
        </div>
      </div>
    </BaseCard>
  );
};

export default PayForm;
