import React, { useEffect, useState } from "react";
import useUserLocation from "../../hooks/useUserLocation";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  fetchFreelancerJobs,
  fetchJobsCount,
  findJobs,
} from "../../services/api/workOrder";
import FindJobTable from "./FindJobTable";
import FindJobListingHeader from "./FindJobListingHeader";
import { logout } from "../../utils/helpers";
import BaseLoader from "../../components/ui/BaseLoader";

const FindJob = () => {
  const [radius, setRadius] = useState(1);

  const [freelancerJobs, setFreelancerJobs] = useState([]);

  const {
    userCurrentLocation,
    error: locationError,
    isLoading: isLocationLoading,
  } = useUserLocation();

  const [jobsFilters, setJobsFilters] = useState({
    status: "AVAILABLE",
    isCounter: false,
  });
  const [selectedLocation, setSelectedLocation] = useState(null);

  const {
    isLoading: isJobsCountLoading,
    error: isJobsCountError,
    data: jobsCount,
    refetch: reFetchJobsCount,
  } = useQuery({
    queryKey: ["jobsCount"],
    queryFn: () =>
      fetchJobsCount({
        latitude: selectedLocation?.latitude,
        longitude: selectedLocation?.longitude,
        radius: radius,
      }),
    enabled: !!selectedLocation,
  });

  const {
    isLoading: isWorkOrderLoading,
    error: isWorkOrderError,
    data: jobsData,
    refetch: fetchJobs,
  } = useQuery({
    // queryKey: ["findJobs"],
    queryFn: () =>
      findJobs({
        latitude: selectedLocation?.latitude,
        longitude: selectedLocation?.longitude,
        radius: radius, // Example radius of 5 km
      }),
    enabled: !!selectedLocation,
  });

  const {
    isLoading: isFreelancerJobsLoading,
    error: isFreelancerJobsError,
    data: freealncerjobsData,
    mutateAsync: fetchFreelancerJobsMutation,
  } = useMutation({
    mutationFn: fetchFreelancerJobs,
    enabled: false,
  });

  if (isWorkOrderError?.response?.status === 401) {
    logout();
  }

  useEffect(() => {
    reFetchJobsCount();
    if (jobsFilters.status === "AVAILABLE") {
      setFreelancerJobs(jobsData?.data?.jobs || []);
    } else {
      fetchFreelancerJobsApi();
    }
  }, [jobsFilters, jobsData]);

  useEffect(() => {
    if (userCurrentLocation) {
      setSelectedLocation({
        latitude: userCurrentLocation?.latitude,
        longitude: userCurrentLocation?.longitude,
      });
    }
  }, [userCurrentLocation]);

  const handleRadiusChange = (newRadius) => {
    setRadius(newRadius);
  };
  const handleLocationSearch = (address) => {
    setSelectedLocation({
      latitude: address?.latitude,
      longitude: address?.longitude,
    });
  };

  const fetchFreelancerJobsApi = async () => {
    try {
      const response = await fetchFreelancerJobsMutation({
        ...jobsFilters,
        latitude: userCurrentLocation?.latitude,
        longitude: userCurrentLocation?.longitude,
      });
      setFreelancerJobs(response?.data);
    } catch (error) {}
  };

  return (
    <>
      <BaseLoader isLoading={isFreelancerJobsLoading || isLocationLoading} />
      <div className="bg-[#F6F6F6] h-screen pt-4">
        <FindJobListingHeader
          radius={radius}
          handleRadiusChange={handleRadiusChange}
          fetchJobs={fetchJobs}
          handleLocationSearch={handleLocationSearch}
        />
        <FindJobTable
          jobs={freelancerJobs}
          setJobsFilters={setJobsFilters}
          jobsCount={jobsCount?.data}
        />
      </div>
    </>
  );
};

export default FindJob;
