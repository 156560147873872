import React, { useEffect, useState } from "react";
import BaseButton from "../../../components/ui/BaseButton";
import BaseRadio from "../../../components/ui/BaseRadio";
import { RadioGroup } from "@mui/material";
import BaseModal from "../../../components/ui/BaseModal";
import { brandPrimaryColor } from "../../../utils/variables";
import Pay from "./Pay";
import Schedule from "./Schedule";
import Expenses from "./Expenses";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import "./style.css";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  createCounterOffer,
  deleteOfferExpense,
  fetchUserCounterOffer,
} from "../../../services/api/offers";
import { showToast } from "../../../store/toast/toastSlice";
import useAuth from "../../../hooks/useAuth";
import BaseCard from "../../../components/ui/BaseCard";
import QualificationMatcher from "../components/QualificationMatcher";
import BaseCheckbox from "../../../components/ui/BaseCheckbox";
import BaseTextField from "../../../components/ui/BaseTextField";
import BaseSelect from "../../../components/ui/BaseSelect";
import { fetchWorkOrderPaymetDetails } from "../../../services/api/workOrder";
import { useParams } from "react-router-dom";
import { calculateNewEstimatedHourlyTotal } from "../../../utils/helpers";

const resetExpense = {
  expenseType: "",
  description: "",
  amount: "",
  addedBy: "FREELANCER",
  isApproved: false,
};

const resetPaymentForm = {
  hourlyRate: "",
  minimumHours: "",
  totalNewWorkOrderCost: "",
};

const CounterOfferModal = ({
  counterOfferModalOpen,
  setCounterOfferModalOpen,
  refetchUserAllowedAction,
  mode,
}) => {
  const workOrderDetail = useSelector(
    (state) => state.workOrder.workOrderDetails
  );
  const dispatch = useDispatch();
  const { authRole, auth } = useAuth();
  const { id } = useParams();
  const [isAllQualificationsPassed, setIsAllQualificationsPassed] =
    useState(false);
  const [counterOfferType, setCounterOfferType] = useState("PAY");
  const [isLabourOpen, setIsLabourOpen] = useState(false);
  const [isExpensesOpen, setIsExpensesOpen] = useState(false);
  const [expenses, setExpenses] = useState([]);
  const [newExpense, setNewExpense] = useState(resetExpense);
  const [paymentForm, setPaymentForm] = useState({});
  const [newPayment, setNewPayment] = useState({});
  const {
    isLoading,
    error,
    data: workOrderPaymentData,
  } = useQuery({
    queryFn: () => fetchWorkOrderPaymetDetails(id),
    enabled: !!id,
    queryKey: ["workOrderPaymentData"],
    // enabled: false,
  });
  const {
    isLoading: isUserCounterOfferDataLoading,
    error: isUserCounterOfferDataError,
    data: userCounterOfferData,
  } = useQuery({
    queryKey: ["userCounterOfferData"],
    queryFn: () => fetchUserCounterOffer({ id, userId: auth?.user?.id }),
    enabled: !!id && !!auth && mode === "edit",
  });
  const {
    status,
    error: isCreateOfferError,
    mutateAsync: createCounterOfferMutation,
  } = useMutation({
    mutationFn: createCounterOffer,
    onSuccess: (data) => {
      dispatch(
        showToast({
          message: "Counter Offer Sent Successfully",
          type: "success",
        })
      );
    },
  });

  const {
    isLoading: isOfferExpenseLoading,
    error: isOfferExpenseError,
    mutateAsync: deleteOfferExpenseMutation,
  } = useMutation({
    mutationFn: deleteOfferExpense,
    onSuccess: (data) => {
      dispatch(
        showToast({
          message: "Expense Deleted Successfully",
          type: "success",
        })
      );
    },
  });

  useEffect(() => {
    if (mode === "add") return;
    const mergedExpenses = [
      ...(userCounterOfferData?.data?.expenses || []),
      ...(workOrderPaymentData?.data?.expenses || []),
    ];
    setExpenses(mergedExpenses);

    const totalNewWorkOrderCost = calculateNewEstimatedHourlyTotal(
      mergedExpenses,
      userCounterOfferData?.data?.hourlyRate,
      userCounterOfferData?.data?.minimumHours
    );

    setNewPayment({
      hourlyRate: userCounterOfferData?.data?.hourlyRate,
      minimumHours: userCounterOfferData?.data?.minimumHours,
      totalNewWorkOrderCost,
    });

    setPaymentForm({
      hourlyRate: userCounterOfferData?.data?.hourlyRate,
      minimumHours: userCounterOfferData?.data?.minimumHours,
    });
  }, [userCounterOfferData]);

  useEffect(() => {
    if (mode === "edit") return;

    setExpenses(
      workOrderPaymentData?.data?.expenses?.map((expense) => {
        return {
          ...expense,
          isApproved: true,
        };
      })
    );
    setPaymentForm({
      hourlyRate: workOrderPaymentData?.data?.hourlyRate,
      minimumHours: workOrderPaymentData?.data?.minimumHours,
    });
  }, [workOrderPaymentData]);

  const handleCounterOfferButton = async () => {
    const filteredExpenses = expenses.filter(
      (expense) => expense.addedBy === "FREELANCER"
    );

    const payload = {
      workOrderId: workOrderDetail?.id,
      hourlyRate: newPayment.hourlyRate,
      minimumHours: newPayment.minimumHours,
      expenses: filteredExpenses,
    };
    try {
      await createCounterOfferMutation(payload);
      refetchUserAllowedAction();
      setCounterOfferModalOpen(false);
    } catch (error) {
      dispatch(
        showToast({
          message: isCreateOfferError?.response?.data?.message,
          type: "error",
        })
      );
    }
  };

  const handleExpenseChange = (e) => {
    const { name, value } = e.target;
    setNewExpense((prevState) => ({
      ...prevState,
      [name]: name === "amount" ? parseFloat(value) : value,
    }));
  };

  const handleLabourChange = (e) => {
    const { name, value } = e.target;
    setPaymentForm((prevState) => ({
      ...prevState,
      [name]: parseFloat(value),
    }));
  };

  const handleAddExpenseButton = () => {
    if (
      newExpense.expenseType == "" ||
      newExpense.amount === "" ||
      newExpense.amount === null ||
      isNaN(newExpense.amount)
    ) {
      dispatch(
        showToast({
          message: "Please enter Expense Values",
          type: "error",
        })
      );
      return;
    }
    const updatedExpenses = [...expenses, newExpense];

    setExpenses((prevItems) => [
      ...prevItems,
      {
        ...newExpense,
        amount: parseFloat(newExpense?.amount),
        isApproved: false,
      },
    ]);

    const hourlyRate =
      newPayment.hourlyRate || workOrderPaymentData?.data?.hourlyRate;
    const minimumHours =
      newPayment.minimumHours || workOrderPaymentData?.data?.minimumHours;
    const totalNewWorkOrderCost = calculateNewEstimatedHourlyTotal(
      updatedExpenses,
      hourlyRate,
      minimumHours
    );

    setNewPayment({ ...newPayment, totalNewWorkOrderCost });

    setNewExpense(resetExpense);
  };

  const handleAddHourlyPay = () => {
    if (
      paymentForm.hourlyRate == "" ||
      paymentForm.hourlyRate === null ||
      isNaN(paymentForm.hourlyRate) ||
      paymentForm.minimumHours == "" ||
      paymentForm.minimumHours === null ||
      isNaN(paymentForm.minimumHours)
    ) {
      dispatch(
        showToast({
          message: "Please enter Labour Values",
          type: "error",
        })
      );
      return;
    }
    const totalNewWorkOrderCost = calculateNewEstimatedHourlyTotal(
      expenses,
      paymentForm?.hourlyRate,
      paymentForm?.minimumHours
    );

    setNewPayment({ ...paymentForm, totalNewWorkOrderCost });
    setPaymentForm(resetPaymentForm);
  };

  const handleDeleteExpenseButton = async (expenseId) => {
    try {
      const deleteExpenseResponse = await deleteOfferExpenseMutation(expenseId);

      const filteredExpenses = expenses.filter(
        (expense) => expense.id !== deleteExpenseResponse?.data?.id
      );

      setExpenses(filteredExpenses);
    } catch (error) {}
  };

  return (
    <BaseModal
      style={{
        width: "1025px",
      }}
      open={counterOfferModalOpen}
      close={() => setCounterOfferModalOpen(false)}
    >
      <div className="container bg-light px-10">
        <h5 className="text-dark border-bottom mb-3">Counter Offer</h5>
        <BaseCard className="p-4 mt-6" elevation={0}>
          <h6 className="text-dark mb-3">
            WO Qualifications
            {!isAllQualificationsPassed && (
              <>
                <span>: </span>
                <span className="text-xs text-red-600">
                  (To proceed with this work order request, please ensure you
                  add the required qualifications)
                </span>
              </>
            )}
          </h6>
          <QualificationMatcher
            setIsAllQualificationsPassed={setIsAllQualificationsPassed}
            workOrderQualifications={workOrderDetail?.qualificationSubTypes}
          />
        </BaseCard>

        <BaseCard className="p-4 mt-6" elevation={0}>
          <div className="flex justify-between border-bottom mb-2">
            <h6 className="text-dark">Schedule</h6>
            <div className="flex items-center bg-[#F2FAFF] py-1 px-4 rounded-md mb-2">
              <i className="fa-solid fa-circle-exclamation text-primary"></i>
              <p className=" ml-3 font-normal">Fixed Start</p>
            </div>
          </div>
          <div className="flex justify-between">
            <p>Start at a specific date and time</p>
            <p>
              {dayjs(workOrderDetail.orderStartDate).format("dddd, MM/DD/YYYY")}
               at {dayjs(workOrderDetail.orderStartDate).format("hh:mm A")}{" "}
              <br />({workOrderDetail?.address?.timeZone})
            </p>
          </div>
          <div>
            <h6>Labour</h6>
            <div className="flex items-center mt-4 mb-2">
              <p className="mr-5">Hourly Rate</p>
              <div className="border-t border-dotted border-gray-500  flex-grow"></div>

              <p
                className={`ml-5 font-normal  ${
                  newPayment?.hourlyRate && "line-through"
                }`}
              >
                $ {workOrderPaymentData?.data?.hourlyRate?.toFixed(2)}
              </p>
              {newPayment?.hourlyRate && (
                <p className="ml-3 font-normal">
                  ${parseFloat(newPayment?.hourlyRate)?.toFixed(2)}
                </p>
              )}
            </div>
            <div className="flex items-center mb-2">
              <p className="mr-5">Minimum Payable Hours</p>
              <div className="border-t border-dotted border-gray-500  flex-grow"></div>
              <p
                className={`ml-5 font-normal  ${
                  newPayment?.minimumHours && "line-through"
                }`}
              >
                {workOrderPaymentData?.data?.minimumHours}
                hrs
              </p>
              {newPayment?.minimumHours && (
                <p className="ml-3 font-normal">
                  {newPayment?.minimumHours}hrs
                </p>
              )}
            </div>
          </div>

          <div className="mt-6">
            <h6>Expenses</h6>
            {expenses?.length > 0 &&
              expenses?.map((expense, index) => (
                <div className="flex items-center mt-2 mb-3" key={index}>
                  <p className="mr-5">
                    {expense?.expenseType} ({expense?.description})
                  </p>
                  <div className="border-t border-dotted border-gray-500  flex-grow"></div>
                  <div className="flex items-center bg-light py-1 px-3 rounded-md ml-5">
                    {expense.isApproved ? (
                      <i className="fa-solid fa-check"></i>
                    ) : (
                      <i className="fa-solid fa-xmark"></i>
                    )}
                    <p className="ml-2 font-normal">
                      {expense?.addedBy?.role?.role === "CLIENT"
                        ? "Buyer"
                        : "Provider"}
                      : ${expense?.amount?.toFixed(2) || 0}
                    </p>
                  </div>
                  {authRole === "FREELANCER" &&
                    expense?.addedBy?.role?.role === "FREELANCER" && (
                      <i
                        className="fa-solid fa-trash-can text-red-500 text-base ml-2 cursor-pointer"
                        onClick={() => handleDeleteExpenseButton(expense?.id)}
                      ></i>
                    )}
                </div>
              ))}
          </div>

          <div className="mt-6">
            <div className="flex justify-between bg-primary px-4 py-1 rounded-md">
              <h6 className="text-white">Total Estimate</h6>
              <div className="flex">
                <h6
                  className={`mr-6 text-white ${
                    newPayment.totalNewWorkOrderCost && "line-through"
                  }`}
                >
                  ${workOrderPaymentData?.data?.totalWorkOrderCost?.toFixed(2)}
                </h6>
                {newPayment?.totalNewWorkOrderCost && (
                  <h6 className="text-white">
                    ${parseFloat(newPayment?.totalNewWorkOrderCost)?.toFixed(2)}
                  </h6>
                )}
              </div>
            </div>

            <p className="mt-3 text-primary pr-28">
              <span className="font-normal">Please not:</span> The estimated
              cost is the the minimum payable amount against this work order.
              However the actual pay will depend upon the time you spent to
              complete the job.
            </p>
          </div>
        </BaseCard>

        <BaseCard className="p-4 mt-6" elevation={0}>
          <div>
            <div className="flex items-center">
              <BaseCheckbox
                onChange={(e) => setIsLabourOpen(e.target.checked)}
              />
              <h6 className="text-base font-normal">Labour</h6>
            </div>

            {isLabourOpen && (
              <div className="grid grid-cols-7 gap-10 items-center">
                <div className="col-span-6 grid grid-cols-3 gap-10 items-end mb-2">
                  <BaseTextField
                    value="Hourly"
                    isDefaultField={false}
                    disabled={true}
                  />
                  <BaseTextField
                    isDefaultField={false}
                    label="Rate"
                    labelShrink={false}
                    optional={false}
                    name="hourlyRate"
                    value={paymentForm?.hourlyRate}
                    onChange={(e) => handleLabourChange(e)}
                    type="number"
                  />
                  <BaseTextField
                    isDefaultField={false}
                    label="Minimum Time"
                    labelShrink={false}
                    optional={false}
                    name="minimumHours"
                    value={paymentForm?.minimumHours}
                    type="number"
                    onChange={(e) => handleLabourChange(e)}
                  />
                </div>

                <BaseButton
                  variant="outlined"
                  color="primary"
                  className="py-[1px] h-10 mt-3 hover:bg-primary bg-zinc-200/80"
                  fullWidth={false}
                  onClick={handleAddHourlyPay}
                >
                  Add
                </BaseButton>
              </div>
            )}
          </div>
          <div>
            <div className="flex items-center">
              <BaseCheckbox
                onChange={(e) => setIsExpensesOpen(e.target.checked)}
              />
              <h6 className="text-base font-normal">Expenses</h6>
            </div>

            {isExpensesOpen && (
              <div className="grid grid-cols-7 gap-10 items-center">
                <div className="col-span-6 grid grid-cols-3 gap-10 items-end">
                  <BaseSelect
                    isDefaultField={false}
                    labelShrink={false}
                    className="py-[5px] mb-1.5"
                    label=""
                    required={true}
                    onChange={(e) => handleExpenseChange(e)}
                    options={[
                      { label: "Travel Expense", value: "TRAVEL" },
                      { label: "Tools Expense", value: "TOOLS" },
                      { label: "Material Expense", value: "MATERIAL" },
                      { label: "All Options", value: "ALL" },
                    ]}
                    name="expenseType"
                    value={newExpense.expenseType}
                  />
                  <BaseTextField
                    isDefaultField={false}
                    label="Description"
                    name="description"
                    labelShrink={false}
                    onChange={(e) => handleExpenseChange(e)}
                    optional={false}
                    value={newExpense.description}
                  />
                  <BaseTextField
                    isDefaultField={false}
                    label="Total Amount"
                    labelShrink={false}
                    optional={false}
                    onChange={(e) => handleExpenseChange(e)}
                    name="amount"
                    type="number"
                    value={newExpense.amount}
                  />
                </div>
                <BaseButton
                  variant="outlined"
                  color="primary"
                  className="py-[1px] h-10 mt-5 hover:bg-primary bg-zinc-200/80"
                  fullWidth={false}
                  onClick={handleAddExpenseButton}
                >
                  Add
                </BaseButton>
              </div>
            )}
          </div>
        </BaseCard>
      </div>

      <div
        className="flex justify-center py-8 shadow-xl"
        style={{
          boxShadow: "0px 0px 14px 5px rgba(0, 0, 0, 0.07)",
        }}
      >
        <BaseButton
          fullWidth={false}
          className="py-[4px] mr-8"
          variant="outlined"
          color="primary"
          onClick={() => setCounterOfferModalOpen(false)}
        >
          Cancel
        </BaseButton>
        <BaseButton
          fullWidth={false}
          className="py-[5px]"
          color="primary"
          onClick={handleCounterOfferButton}
        >
          Send Counter Offer
        </BaseButton>
      </div>
    </BaseModal>
  );
};

export default CounterOfferModal;
