import { useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { setWorkOrderDetail } from "../store/workOrder/workOrderSlice";
import { useParams } from "react-router-dom";
import { fetchJobDetail } from "../services/api/workOrder";
import React from "react";

export function useUpdateWorkOrder() {
  const { id } = useParams();
  const dispatch = useDispatch();

  const {
    isLoading,
    error,
    data: jobDetailData,
    refetch: updateWorkOrder,
  } = useQuery({
    queryKey: ["jobDetail"],
    queryFn: () => fetchJobDetail(id),
    enabled: !!id,
  });

  // Dispatch work order details to the store when `jobDetailData` changes
  React.useEffect(() => {
    if (jobDetailData?.data) {
      dispatch(setWorkOrderDetail(jobDetailData?.data));
    }
  }, [jobDetailData, dispatch]);

  return { isLoading, error, jobDetailData, updateWorkOrder }; // Return `updateWorkOrder` for external call
}
