import React, { useState } from "react";
import BaseModal from "../../../components/ui/BaseModal";
import BaseButton from "../../../components/ui/BaseButton";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { showToast } from "../../../store/toast/toastSlice";
import { useUpdateWorkOrder } from "../../../hooks/useUpdateWorkOrder";
import { updateTask } from "../../../services/api/task";

import BaseRadio from "../../../components/ui/BaseRadio";
import { RadioGroup } from "@mui/material";

const MarkCompleteModal = ({
  markCompleteModalOpen,
  setMarkCompleteModalOpen,
  handleCloseNotes,
  setCloseNotes,
  tasks,
  setEngineerFeedbackModalOpen,
}) => {
  const [isYes, setIsYes] = useState("");
  const [reason, setReason] = useState("");
  const dispatch = useDispatch();
  const { updateWorkOrder } = useUpdateWorkOrder();

  const {
    isLoading: isTaskUpdateLoading,
    error: isTaskUpdateError,
    data: updatedTaskData,
    mutateAsync: updateTaskMutation,
  } = useMutation({
    mutationFn: updateTask,
    enabled: false,
  });

  const handleMarkComplete = async () => {
    if (isYes === "" || isYes.length < 1) {
      dispatch(
        showToast({
          message: "please select either Yes or No",
          type: "error",
        })
      );

      return;
    }
    const task = tasks?.find((task) => task.taskType === "MARK_COMPLETE");
    let isCompleted;
    if (isYes === "YES") {
      isCompleted = true;
    } else if (isYes === "NO") {
      isCompleted = false;
    }
    try {
      let payload = {
        id: task.completedTask?.id,
        isCompleted,
        text: reason,
      };

      const updatedTask = await updateTaskMutation(payload);

      await handleCloseNotes();
      setMarkCompleteModalOpen(false);
      setCloseNotes("");
      setIsYes("");
      updateWorkOrder();
      setReason("");
    } catch (error) {
      dispatch(
        showToast({
          message: error?.response?.data?.message,
          type: "error",
        })
      );
    }
  };

  const handleChange = (e) => {
    setIsYes(e.target.value);
  };

  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  return (
    <BaseModal
      open={markCompleteModalOpen}
      close={() => {}}
      style={{ width: "700px" }}
    >
      <div className="container">
        <h6 className="text-dark border-bottom mb-4 pb-1">Mark Complete</h6>
        <p className="bg-primary px-3 py-2 text-white rounded">
          Is the full scope of work completed?
        </p>

        <p className="my-3">
          This help determine whether additional work is required
        </p>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue={isYes}
          name="radio-buttons-group"
        >
          <div>
            <BaseRadio
              label={<p className="text-sm">Yes</p>}
              value="YES"
              name="yes"
              onChange={handleChange}
            />

            <BaseRadio
              label={<p className="text-sm">No</p>}
              value="NO"
              name="yes"
              onChange={handleChange}
            />
          </div>
        </RadioGroup>

        {isYes === "NO" && (
          <>
            <p className="font-normal mt-3">Reason</p>
            <textarea
              className="shadow border-t border-zinc-100	w-full rounded-md h-32 resize-none p-4 mt-1 focus:outline-none focus:ring-1 focus:ring-orange-400 text-xs font-[300] text-dark"
              onChange={(e) => handleReasonChange(e)}
            ></textarea>
          </>
        )}
      </div>
      <div
        className="flex items-center justify-center bg-white py-6 px-5 mt-3"
        style={{
          boxShadow: "5px 0px 14px 5px rgba(0, 0, 0, 0.07)",
        }}
      >
        <BaseButton
          className="py-[5px] px-2 bg-[#E8E8E8] text-primary"
          fullWidth={false}
          onClick={() => setMarkCompleteModalOpen(false)}
        >
          Cancel
        </BaseButton>
        <BaseButton
          disabled={isTaskUpdateLoading}
          isLoading={isTaskUpdateLoading}
          className="py-[5px] px-4 ml-4"
          color="primary"
          fullWidth={false}
          onClick={handleMarkComplete}
        >
          Mark Complete
        </BaseButton>
      </div>
    </BaseModal>
  );
};

export default MarkCompleteModal;
