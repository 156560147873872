import React from "react";
import BaseTextField from "../../../../components/ui/BaseTextField";
import { useDispatch, useSelector } from "react-redux";
import { updateWorkOrderForm } from "../../../../store/workOrder/workOrderSlice";

const HalfDayPay = () => {
  const dispatch = useDispatch();
  const payment = useSelector((state) => state.workOrder.payment.pay);

  const handleChange = (event) => {
    const { name, value } = event.target;
    dispatch(
      updateWorkOrderForm({
        step: "payment",
        data: {
          pay: {
            [name]: parseFloat(value),
          },
        },
      })
    );
  };

  return (
    <div className="grid grid-cols-2 gap-y-4 mt-3">
      <div className="mr-10">
        <BaseTextField
          fullWidth
          fullHeight={false}
          id="fixedAmount"
          name="fixedAmount"
          label="Half Day Rate"
          optional={false}
          labelShrink={false}
          value={payment.fixedAmount}
          onChange={handleChange}
          isDefaultField={false}
          type="number"
        />
      </div>
      <div>
        <BaseTextField
          fullWidth
          fullHeight={false}
          id="title"
          name="allowedHours"
          label="Additional Hour Rate"
          optional={false}
          labelShrink={false}
          value={payment.allowedHours}
          onChange={handleChange}
          isDefaultField={false}
          type="number"
        />
        <p className="mt-2 text-sm text-right">
          Additional hourly rate will be applicable after 4 hours of Work
        </p>
      </div>
    </div>
  );
};

export default HalfDayPay;
