import React, { useState } from "react";
import { LoadScript } from "@react-google-maps/api";
import BaseLoader from "./ui/BaseLoader";

const LIBRARIES = ["places"];

const GoogleMapsProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleLoad = () => {
    setIsLoading(false);
  };

  const handleError = () => {
    setIsLoading(false); // Hide loader on error, you can also show an error message here
  };

  return (
    <>
      {isLoading && <BaseLoader isLoading={isLoading} />}
      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        libraries={LIBRARIES}
        onLoad={handleLoad}
        onError={handleError}
      >
        {children}
      </LoadScript>
    </>
  );
};

export default GoogleMapsProvider;
