import React, { useEffect, useState } from "react";
import CustomTable from "../../../components/CustomTable";
import {
  assignWorkOrderToFreelancer,
  deleteOffer,
  fetchWorkOrderOffers,
  updateWorkOrderStatus,
} from "../../../services/api/offers";
import { useDispatch } from "react-redux";
import { useMutation, useQuery } from "@tanstack/react-query";
import { showToast } from "../../../store/toast/toastSlice";
import BaseButton from "../../../components/ui/BaseButton";
import { Avatar } from "@mui/material";
import BaseRating from "../../../components/ui/BaseRating";
import { useNavigate, useParams } from "react-router-dom";
import { useUpdateWorkOrder } from "../../../hooks/useUpdateWorkOrder";
import BaseSelect from "../../../components/ui/BaseSelect";
import ReviewCounterOfferModal from "../ReviewCounterOfferModal";
import BaseNotFound from "../../../components/ui/BaseNotFound";

const PendingAssignment = ({
  filters,
  radius,
  setRadius,
  refetchProvidersCount,
}) => {
  const navigate = useNavigate();
  const [reviewCounterOfferModalOpen, setReviewCounterOfferModalOpen] =
    useState(false);
  const [freelancerId, setFreelancerId] = useState(null);
  const [offerId, setOfferId] = useState(null);
  const { id } = useParams();
  const dispatch = useDispatch();

  const { updateWorkOrder } = useUpdateWorkOrder();

  const {
    isLoading: isTechniciansLoading,
    error: isTechniciansError,
    data: freelancersData,
    refetch: refetchWorkOrderOffers,
  } = useQuery({
    queryKey: ["filteredFreelancers"],
    queryFn: () =>
      fetchWorkOrderOffers({
        id,
        filters: filters,
      }),
  });

  const {
    isPending: isOfferDeleteLoading,
    error: isOfferDeleteError,
    mutateAsync: deleteOfferMutation,
  } = useMutation({
    mutationFn: deleteOffer,
    onSuccess: (data) => {
      dispatch(
        showToast({
          message: "UnRouted Successfully",
          type: "success",
        })
      );

      updateWorkOrderDetailPageUI();
    },
  });

  const {
    isPending: isAssignLoading,
    status,
    error: isAssignWorkOrderError,
    mutateAsync: assignWorkOrderToFreelancerMutation,
  } = useMutation({
    mutationFn: assignWorkOrderToFreelancer,
    onSuccess: (data) => {
      dispatch(
        showToast({
          message: "Job Assign Successfully",
          type: "success",
        })
      );
      updateWorkOrderDetailPageUI();
    },
    onError: (error) => {
      dispatch(
        showToast({
          message: error?.response?.data?.message,
          type: "error",
        })
      );
    },
  });

  const {
    isPending: isUpdateWorkOrderLoading,
    // status,
    error: isupdateWorkOrderStatusError,
    mutateAsync: updateWorkOrderStatusMutation,
  } = useMutation({
    mutationFn: updateWorkOrderStatus,
    onSuccess: (data) => {
      dispatch(
        showToast({
          message: "Job Updated Successfully",
          type: "success",
        })
      );
      updateWorkOrderDetailPageUI();
    },
  });

  async function handleAssignWorkOrderButton(userId, workOrderId) {
    try {
      let payload = { userId, workOrderId };
      await assignWorkOrderToFreelancerMutation(payload);
      updateWorkOrderDetailPageUI();
    } catch (error) {
      dispatch(
        showToast({
          message: isupdateWorkOrderStatusError?.response?.data?.message,
          type: "error",
        })
      );
    }
  }

  async function handleUpdateWorkOrderStatusButton(
    userId,
    workOrderId,
    status = "PENDING"
  ) {
    try {
      let payload = { userId, workOrderId, status };
      await updateWorkOrderStatusMutation(payload);
      updateWorkOrderDetailPageUI();
    } catch (error) {
      dispatch(
        showToast({
          message: isAssignWorkOrderError?.response?.data?.message,
          type: "error",
        })
      );
    }
  }

  useEffect(() => {
    refetchWorkOrderOffers();
  }, [radius]);

  const data = freelancersData?.data || [];

  const updateWorkOrderDetailPageUI = () => {
    refetchWorkOrderOffers();
    updateWorkOrder();
    refetchProvidersCount();
  };

  const handleReviewCounterOfferButton = (freelancerId, offerId) => {
    setFreelancerId(freelancerId);
    setOfferId(offerId);
    setReviewCounterOfferModalOpen(true);
  };

  const COLUMNS = [
    {
      label: "Action",
      width: "300px",
      renderCell: (item) => (
        <div className="flex items-center overflow-scroll h-10">
          {!item.isCounter && item.status === "PENDING" && (
            <BaseButton
              loaderSize={18}
              loaderColor="primary"
              isLoading={isAssignLoading}
              style={{
                boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.15)",
                paddingTop: "0px",
                paddingBottom: "0px",
              }}
              onClick={() =>
                handleAssignWorkOrderButton(item.user.id, item.workOrderId)
              }
              className="bg-white hover:bg-primary hover:text-white text-primary ml-0.5  mr-3 w-[80px]"
            >
              Assign
            </BaseButton>
          )}
          {item.status === "PENDING" && (
            <BaseButton
              style={{
                boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.15)",
                paddingTop: "0px",
                paddingBottom: "0px",
              }}
              className="bg-white hover:bg-primary hover:text-white text-primary  mr-3 w-[70px]"
              onClick={() =>
                handleUpdateWorkOrderStatusButton(
                  item.user.id,
                  item.workOrderId,
                  "REJECTED"
                )
              }
            >
              Hide
            </BaseButton>
          )}
          {item.isCounter && item.status === "PENDING" && (
            <BaseButton
              onClick={() =>
                handleReviewCounterOfferButton(item.user.id, item?.id)
              }
              style={{
                boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.15)",
                paddingTop: "0px",
                paddingBottom: "0px",
              }}
              className="bg-white hover:bg-primary hover:text-white text-primary  mr-3 w-[100px]"
            >
              Review
            </BaseButton>
          )}

          {item.status === "ASSIGNED" && (
            <BaseButton
              loaderSize={18}
              loaderColor="primary"
              isLoading={isUpdateWorkOrderLoading}
              style={{
                boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.15)",
                paddingTop: "0px",
                paddingBottom: "0px",
              }}
              onClick={() =>
                handleUpdateWorkOrderStatusButton(
                  item.user.id,
                  item.workOrderId
                )
              }
              className="bg-white hover:bg-primary hover:text-white text-primary  mr-3 w-[102px]"
            >
              un-Assign
            </BaseButton>
          )}
          {item.status === "ROUTED" && (
            <BaseButton
              loaderSize={18}
              loaderColor="primary"
              isLoading={isOfferDeleteLoading}
              style={{
                boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.15)",
                paddingTop: "0px",
                paddingBottom: "0px",
              }}
              className="bg-white hover:bg-primary hover:text-white text-primary mr-3 w-[100px]"
              onClick={() => deleteOfferMutation(item.id)}
            >
              un-route
            </BaseButton>
          )}
          <BaseButton
            style={{
              boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.15)",
              paddingTop: "0px",
              paddingBottom: "0px",
            }}
            className="bg-white hover:bg-primary hover:text-white text-primary mr-3 w-[80px]"
          >
            Message
          </BaseButton>
        </div>
      ),
    },
    {
      label: "ID",
      renderCell: (item) => (
        <p className="text-primary">{item?.user?.id || item?.id}</p>
      ),
    },
    {
      label: "Name",
      renderCell: (item) => (
        <div
          className="flex items-center cursor-pointer"
          onClick={() => navigate(`/profile/details/${item?.user.id}`)}
        >
          <Avatar src="/broken-image.jpg" sx={{ width: 30, height: 30 }} />
          <div className="ml-2">
            <p className="text-primary capitalize">
              {item?.user?.firstName} {item?.user?.lastName}
            </p>
            <p className="text-xs">{item?.user?.phoneNumber}</p>
          </div>
        </div>
      ),
    },
    {
      label: "Private Feedback",
      renderCell: (item) => <p>not enough data</p>,
    },
    {
      label: "Ratings",
      renderCell: (item) => (
        <div className="flex items-center justify-center border border-primary w-20 py-1 rounded-full">
          <BaseRating style={{ fontSize: "13px" }} />
        </div>
      ),
    },
    {
      label: "Jobs",
      renderCell: (item) => (
        <div className="flex items-center">
          <p className="text-primary font-normal">336 </p>
          <p className="text-xs ml-1">Marketplace</p>
        </div>
      ),
    },
    {
      label: "Distance",
      renderCell: (item) => (
        <div className="flex items-center">
          <i className="fa-solid fa-car text-xs"></i>
          <p className="text-primary ml-2">
            {item?.user?.distance?.toFixed(2) || 0} km
          </p>
        </div>
      ),
    },
    {
      label: "Ranking",
      renderCell: (item) => (
        <div className="flex items-center">
          <p className="ml-2">Points 374/530</p>
        </div>
      ),
    },
    {
      label: "Cancelation",
      renderCell: (item) => (
        <div className="flex items-center">
          <p className="ml-2">13%</p>
        </div>
      ),
    },
    {
      label: "Timeliness",
      renderCell: (item) => (
        <div className="flex items-center">
          <p className="ml-2">85%</p>
        </div>
      ),
    },
  ];

  const handleRadiusChange = (e) => {
    setRadius(e.target.value);
  };

  return (
    <>
      <div className="mb-3 flex items-center">
        <div className="flex items-center w-[250px]">
          <p className="mr-2 mt-1">Distance: </p>
          <BaseSelect
            className="w-[150px] rounded-lg "
            fullHeight={true}
            fullWidth
            labelShrink={false}
            required={true}
            id="workOrderManagerId"
            name="workOrderManagerId"
            value={radius}
            onChange={handleRadiusChange}
            isDefaultField={true}
            options={[
              {
                label: "50 km",
                value: 50,
              },
              {
                label: "100 km",
                value: 100,
              },
              {
                label: "150 km",
                value: 150,
              },
            ]}
          />
        </div>
        <div className="flex items-center w-[400px]">
          <p className="mr-2">Providers:</p>
          <p className="ml-2 bg-[#EFF4FF] px-5 py-1 rounded-md">
            {data?.length}
          </p>
        </div>
      </div>
      {data.length > 0 ? (
        <CustomTable columns={COLUMNS} data={data} currentFilter={filters[0]} />
      ) : (
        <BaseNotFound />
      )}

      {reviewCounterOfferModalOpen && (
        <ReviewCounterOfferModal
          reviewCounterOfferModalOpen={reviewCounterOfferModalOpen}
          setReviewCounterOfferModalOpen={setReviewCounterOfferModalOpen}
          freelancerId={freelancerId}
          offerId={offerId}
          updateWorkOrderDetailPageUI={updateWorkOrderDetailPageUI}
          handleUpdateWorkOrderStatusButton={handleUpdateWorkOrderStatusButton}
        />
      )}
    </>
  );
};

export default PendingAssignment;
