import * as React from "react";
import { CompactTable } from "@table-library/react-table-library/compact";
import { useTheme } from "@table-library/react-table-library/theme";
import { useSort } from "@table-library/react-table-library/sort";
import "./style.css";
import {
  DEFAULT_OPTIONS,
  getTheme,
} from "@table-library/react-table-library/material-ui";
import { brandFontSize } from "../../../utils/variables";
import BaseNotFound from "../BaseNotFound";

const BaseDataTable = ({
  columns = [],
  nodes,
  columnWidths,
  lastColFix = true,
  select,
}) => {
  const data = { nodes };

  const materialTheme = getTheme({
    ...DEFAULT_OPTIONS,
    striped: true,
    highlightOnHover: false,
    select: true,
  });
  const customTheme = {
    Table: `
      --data-table-library_grid-template-columns:  ${columns
        .map((col) => columnWidths[col.label] || "1fr")
        .join(" ")};
      border-radius: 6px;
      border: 1px solid #DEDEDE;
    `,
    HeaderCell: `
      
      background-color: white;
      z-index: 1;
      border-right: 1px solid #8C8C8C;
      height: 36px;
      font-weight: 400;
      font-size: ${brandFontSize};
    `,
    Cell: `
      height: 40px;
      font-weight: 300;
    `,
    BaseCell: `
      &:nth-of-type(1) {
        left: 0px;
        border-right: 1px solid #8C8C8C;
      }
      &:nth-of-type(${columns.length}) ${
      lastColFix
        ? `{
          right: 0;
          left: auto;  /* Ensure last column sticks to the right */
        }`
        : `
          padding:0px;
        `
    }
    `,
  };

  const theme = useTheme([materialTheme, customTheme]);

  function onSortChange(action, state) {}

  const sort = useSort(
    data,
    {
      onChange: onSortChange,
    },
    {
      sortIcon: {
        iconDefault: <i className="fa-solid fa-arrow-up-wide-short"></i>,
        iconUp: <i className="fa-solid fa-arrow-up-wide-short"></i>,
        iconDown: <i className="fa-solid fa-arrow-down-wide-short"></i>,
      },
      sortFns: {
        TASK: (array) =>
          array.sort((a, b) => (a.name || "").localeCompare(b.name || "")),
      },
    }
  );

  return (
    <div
      style={{
        height: data?.nodes?.length > 20 ? "700px" : "",
      }}
    >
      {data?.nodes?.length > 0 ? (
        <CompactTable
          columns={columns}
          data={data}
          theme={theme}
          sort={sort}
          layout={{ custom: true, horizontalScroll: true, fixedHeader: true }}
          select={select}
        />
      ) : (
        <BaseNotFound />
      )}
    </div>
  );
};

export default BaseDataTable;
