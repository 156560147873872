import React, { useState } from "react";
import BaseModal from "../../../components/ui/BaseModal";
import BaseButton from "../../../components/ui/BaseButton";
import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { showToast } from "../../../store/toast/toastSlice";
import { useParams } from "react-router-dom";
import { useUpdateWorkOrder } from "../../../hooks/useUpdateWorkOrder";

import { cancelWorkOrder } from "../../../services/api/workOrder";
import dayjs from "dayjs";

const CancelAssignmentModal = ({
  cancelAssignmentModalOpen,
  setCancelAssignmentModalOpen,
  refetchUserAllowedAction,
}) => {
  const [reason, setReason] = useState("");
  const params = useParams();
  const { updateWorkOrder } = useUpdateWorkOrder();
  const {
    isLoading: isCancelWorkOrderLoading,
    error: isCancelWorkOrderError,
    data: cancelWorkOrderData,
    mutateAsync: cancelWorkOrderMutation,
  } = useMutation({
    mutationFn: cancelWorkOrder,
    enabled: false,
  });

  const handleCancelWorkOrder = async () => {
    try {
      const payload = {
        reason,
        id: params?.id,
      };
      const cancelWorkOrderResponse = await cancelWorkOrderMutation(payload);
      await refetchUserAllowedAction();
      setCancelAssignmentModalOpen(false);
      setReason("");
      updateWorkOrder();
    } catch (error) {
      console.error("Failed to re-fetch and mark work order complete:", error);
    }
  };

  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  return (
    <BaseModal
      open={cancelAssignmentModalOpen}
      close={() => setCancelAssignmentModalOpen(false)}
      style={{ width: "700px" }}
    >
      <div className="container">
        <h6 className="text-dark border-bottom mb-4 pb-1">Cancel Assignment</h6>
        <p className="bg-primary px-3 py-2 text-white rounded">
          Please note that Withdrawal of job will impact your cancellation rate
          on the Marketplace.
        </p>

        <div className="flex items-center justify-between mt-4">
          <p className="font-normal">Cancelled at</p>
          <p>
            {dayjs().format("dddd, MM/D/YYYY")} at {dayjs().format("h:mm A")} (
            {Intl.DateTimeFormat().resolvedOptions().timeZone})
          </p>
        </div>

        <>
          <p className="font-normal mt-8">Reason For Cancellation</p>
          <textarea
            className="bg-zinc-100 border-t border-zinc-100	w-full rounded-md h-28 resize-none p-4 mt-1 focus:outline-none focus:ring-1 focus:ring-orange-400 text-xs font-[300] "
            onChange={(e) => handleReasonChange(e)}
          ></textarea>
        </>
      </div>
      <div
        className="flex items-center justify-center bg-white py-6 px-5 mt-3"
        style={{
          boxShadow: "5px 0px 14px 5px rgba(0, 0, 0, 0.07)",
        }}
      >
        <BaseButton
          className="py-[5px] px-2 bg-[#E8E8E8] text-primary"
          fullWidth={false}
          onClick={() => setCancelAssignmentModalOpen(false)}
        >
          Cancel
        </BaseButton>
        <BaseButton
          disabled={isCancelWorkOrderLoading}
          isLoading={isCancelWorkOrderLoading}
          className="py-[5px] px-4 ml-4"
          color="primary"
          fullWidth={false}
          onClick={handleCancelWorkOrder}
        >
          Cancel Assignment
        </BaseButton>
      </div>
    </BaseModal>
  );
};

export default CancelAssignmentModal;
