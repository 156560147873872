import React from "react";
import WorkOrderStatus from "../../components/WorkOrderStatus";
import dayjs from "dayjs";

const TitleCard = ({ title, id, publishedDate, status }) => {
  return (
    <div className="grid grid-cols-10 items-center w-full h-full">
      <div className="col-span-9 flex items-center">
        <div className="">
          <div className="flex items-center">
            <WorkOrderStatus
              status={status}
              textSize="text-sm"
              width="w-28"
              className="py-[4px]"
            />
            <div className="flex items-end">
              <h6 className="font-normal ml-2">#{id}</h6>
              <p className="ml-2  ">
                On {dayjs(publishedDate).format("MM/DD/YYYY")}
              </p>
            </div>
          </div>
          <h6 className="font-normal mt-2">{title}</h6>
        </div>
      </div>
      <div className="border-r-[1px]  border-zinc-300 mr-6 h-20"></div>
    </div>
  );
};

export default TitleCard;
