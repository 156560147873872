import React, { useState } from "react";
import BaseButton from "../../../components/ui/BaseButton";
import BaseModal from "../../../components/ui/BaseModal";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import {
  assignWorkOrderToFreelancer,
  createOffer,
} from "../../../services/api/offers";
import { showToast } from "../../../store/toast/toastSlice";
import { useMutation } from "@tanstack/react-query";
import useAuth from "../../../hooks/useAuth";
import QualificationMatcher from "../components/QualificationMatcher";
import BaseCard from "../../../components/ui/BaseCard";
import ScheduleCard from "../ScheduleCard";
import LocationCard from "../LocationCard";
import RateCard from "../RateCard";
import LocationMap from "../TabsCard/LocationMap";
import { useUpdateWorkOrder } from "../../../hooks/useUpdateWorkOrder";

const RequestOfferModal = ({
  requestOfferModalOpen,
  setRequestOfferModalOpen,
  refetchUserAllowedAction,
  userAllowedAction,
}) => {
  const dispatch = useDispatch();

  const { auth } = useAuth();
  const { updateWorkOrder } = useUpdateWorkOrder();
  const workOrderDetail = useSelector(
    (state) => state.workOrder.workOrderDetails
  );

  const [isAllQualificationsPassed, setIsAllQualificationsPassed] =
    useState(false);

  const {
    isPending: isCreateOfferLoading,
    status,
    error: isCreateOfferError,
    mutateAsync: createOfferMutation,
  } = useMutation({
    mutationFn: createOffer,
    onSuccess: (data) => {
      dispatch(
        showToast({
          message: "Offer Sent Successfully",
          type: "success",
        })
      );
    },
  });

  const {
    isPending: isAssignLoading,
    error: isAssignWorkOrderError,
    mutateAsync: assignWorkOrderToFreelancerMutation,
  } = useMutation({
    mutationFn: assignWorkOrderToFreelancer,
    onSuccess: (data) => {
      dispatch(
        showToast({
          message: "Job Assign Successfully",
          type: "success",
        })
      );
    },
    onError: (error) => {
      dispatch(
        showToast({
          message: error?.response?.data?.message,
          type: "error",
        })
      );
    },
  });

  async function assignWorkOrder() {
    try {
      let payload = {
        userId: auth.user.id,
        workOrderId: workOrderDetail.id,
      };
      await assignWorkOrderToFreelancerMutation(payload);
    } catch (error) {
      dispatch(
        showToast({
          message: isAssignWorkOrderError?.response?.data?.message,
          type: "error",
        })
      );
    }
  }

  const handleSubmitButton = async () => {
    if (!isAllQualificationsPassed) {
      dispatch(
        showToast({
          message: "Please add qualification to profile",
          type: "error",
        })
      );

      return;
    }
    try {
      if (userAllowedAction.isRouted) {
        await assignWorkOrder();
      } else {
        await createOfferMutation({
          workOrderId: workOrderDetail?.id,
          userId: auth?.user.id,
        });
      }

      updateWorkOrder();
      refetchUserAllowedAction();
      setRequestOfferModalOpen(false);
    } catch (error) {
      dispatch(
        showToast({
          message: isCreateOfferError?.response?.data?.message,
          type: "error",
        })
      );
    }
  };

  return (
    <BaseModal
      className="-top-56"
      style={{
        width: "1000px",
      }}
      open={requestOfferModalOpen}
      close={() => {}}
    >
      <div className="container bg-[#F6F6F6]">
        <h6
          className="text-dark border-bottom"
          style={{ marginBottom: "24px", paddingBottom: "4px" }}
        >
          {userAllowedAction.isRouted
            ? "Accept Work Order"
            : "Request Work Order"}
        </h6>
        <BaseCard className="p-4" elevation={0}>
          <h6 className="text-dark mb-3">
            WO Qualifications
            {!isAllQualificationsPassed && (
              <>
                <span>: </span>
                <span className="text-xs text-red-600">
                  (To proceed with this work order request, please ensure you
                  add the required qualifications)
                </span>
              </>
            )}
          </h6>
          <QualificationMatcher
            setIsAllQualificationsPassed={setIsAllQualificationsPassed}
            workOrderQualifications={workOrderDetail?.qualificationSubTypes}
          />
        </BaseCard>

        <div className="grid grid-cols-3 gap-12 mt-6">
          <RateCard />
          <ScheduleCard
            timeLogs={workOrderDetail?.timeLogs}
            tasks={workOrderDetail?.tasks}
            status={workOrderDetail?.status}
            assignedTo={workOrderDetail?.assignedTo}
            schedule={{
              timeZone: workOrderDetail?.address?.timeZone,
              scheduleType: workOrderDetail?.scheduleType,
              orderStartDate: workOrderDetail?.orderStartDate,
              orderStartTime: workOrderDetail?.orderStartTime,
              orderEndDate: workOrderDetail?.orderEndDate,
              orderEndTime: workOrderDetail?.orderEndTime,
              confirmedWorkOrderAt: workOrderDetail?.tasks?.find(
                (task) => task.taskType === "START_TIME"
              )?.completedTask?.text,
            }}
          />
          <LocationCard location={workOrderDetail?.address} />
        </div>

        <div className="mt-6">
          <LocationMap location={workOrderDetail?.address} />
        </div>
      </div>
      <div className="d-flex content-center  bg-white py-6">
        <BaseButton
          className="request-button bg-light"
          style={{
            marginRight: "16px",
            color: "#686868",
            width: "150px",
          }}
          onClick={() => setRequestOfferModalOpen(false)}
        >
          Cancel
        </BaseButton>

        <BaseButton
          loaderSize={24}
          loaderColor="primary"
          isLoading={isCreateOfferLoading || isAssignLoading}
          className="request-button"
          color="primary"
          style={{
            width: "150px",
          }}
          onClick={handleSubmitButton}
        >
          {userAllowedAction.isRouted ? "Accept" : "Send a Request"}
        </BaseButton>
      </div>
    </BaseModal>
  );
};

export default RequestOfferModal;
