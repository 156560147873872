import React, { useState } from "react";

import BaseCard from "../../../components/ui/BaseCard";
import { useDispatch, useSelector } from "react-redux";
import BaseTextField from "../../../components/ui/BaseTextField";
import BaseSelect from "../../../components/ui/BaseSelect";
import {
  addCustomTask,
  addEmailToNotify,
  addNotification,
  removeNotification,
  taskHandleChange,
  updateEmail,
  updateTaskType,
  updateWorkOrderForm,
} from "../../../store/workOrder/workOrderSlice";

const excludedTaskTypes = ["ON_THE_WAY", "RUNNING_LATE"];

const TasksForm = () => {
  const dispatch = useDispatch();
  const listOfTasks = useSelector((state) => state.workOrder.payment.tasks);

  const filteredTasks = listOfTasks.filter(
    (task) => !excludedTaskTypes.includes(task.taskType)
  );

  const handleChange = (name, value) => {
    dispatch(
      updateWorkOrderForm({
        step: "scheduling",
        data: {
          schedule: {
            [name]: value,
          },
        },
      })
    );
  };

  const handleAddTask = () => {
    // Dispatch the action to add the new task under the existing "On Site" step
    dispatch(addCustomTask({ step: "On Site" }));
  };

  return (
    <BaseCard className="py-4 px-16 mt-2 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4">
        <h6 className="mb-2 text-dark">Tasks</h6>
      </div>
      <div
        className="flex items-center cursor-pointer"
        onClick={() => handleAddTask("CUSTOM")}
      >
        <i className="fa-solid fa-plus text-secondary text-lg"></i>
        <p className="text-secondary ml-1">Add Task</p>
      </div>
      {/* PREP */}
      {filteredTasks?.map((task) => (
        <Task
          title={task.title}
          type={task.taskType}
          emailsToNotify={task.emailsToNotify}
          step={task.step}
          id={task.id}
        />
      ))}
      {/*   <Task
        title="Set start time"
        type="START_TIME"
        emailsToNotify={
          listOfTasks.find((task) => task.taskType === "START_TIME")
            ?.emailsToNotify || []
        }
        step="Prep"
      />
      <Task
        title="Check in"
        type="CHECK_IN"
        emailsToNotify={
          listOfTasks.find((task) => task.taskType === "CHECK_IN")
            ?.emailsToNotify || []
        }
        step="On Site"
      />
      <Task
        title="Check out"
        type="CHECK_OUT"
        emailsToNotify={
          listOfTasks.find((task) => task.taskType === "CHECK_OUT")
            ?.emailsToNotify || []
        }
      />

      <Task
        step="Post"
        title="Enter closeout notes"
        type="CLOSE_NOTES"
        emailsToNotify={
          listOfTasks.find((task) => task.taskType === "CLOSE_NOTES")
            ?.emailsToNotify || []
        }
      /> */}
    </BaseCard>
  );
};

export default TasksForm;

function Task({ title, type, emailsToNotify, step, id }) {
  const dispatch = useDispatch();
  const [email, setEmail] = useState(""); // For email input

  const handleAddNotification = () => {
    dispatch(addNotification());
  };

  const handleRemoveItem = (id) => {
    dispatch(removeNotification({ type, id }));
  };

  const handleTaskTypeChange = (id, taskType) => {
    dispatch(updateTaskType({ id, taskType }));
  };

  const handleEmailChange = (emailId, value) => {
    // Dispatch the action to update the email in the Redux store
    dispatch(updateEmail({ taskType: type, id: emailId, email: value }));
  };

  const handleAddEmail = () => {
    dispatch(addEmailToNotify({ type, email }));
  };

  const handleAddTask = (e, id) => {
    let newTask;

    // Define task structure based on task type
    switch (e.target.value) {
      case "CALL":
        newTask = {
          id,
          taskType: "CALL",
          emailsToNotify: [],
          title: "Call",
          phone: "",
          reason: "",
        };
        break;

      case "COLLECT_SIGNATURE":
        newTask = {
          id,
          taskType: "COLLECT_SIGNATURE",
          emailsToNotify: [],
          title: "Collect signature",
          signatureFrom: "",
        };
        break;

      case "DOWNLOAD_FILE":
        newTask = {
          id,
          taskType: "DOWNLOAD_FILE",
          emailsToNotify: [],
          title: "Download file",
          file: null,
        };
        break;
      case "COMPLETE_CUSTOM_TASK":
        newTask = {
          id,
          taskType: "COMPLETE_CUSTOM_TASK",
          emailsToNotify: [],
          title: "",
          description: "",
        };
        break;
    }

    // Dispatch the action to add the new task under the existing "On Site" step
    dispatch(updateTaskType({ newTask, id }));
  };

  const handleTaskChange = (id, value, field) => {
    dispatch(taskHandleChange({ id, value, field }));
  };

  return (
    <div>
      <p className="text-lg mt-4">{step}</p>
      <div className="bg-light p-3 rounded-md mt-2">
        <div className="flex items-center justify-between border-b pb-2">
          <div className="flex items-center ">
            <i className="fa-solid fa-up-down text-sm"></i>
            <p className="font-normal ml-3">{title}</p>
          </div>
          <i className="fa-solid fa-caret-down cursor-pointer text-lg"></i>
        </div>
        {(type === "CUSTOM" ||
          type === "CALL" ||
          type === "DOWNLOAD_FILE" ||
          type === "COLLECT_SIGNATURE" ||
          type === "COMPLETE_CUSTOM_TASK") && (
          <>
            <BaseSelect
              fullHeight={false}
              placeholder="Select"
              label="Select"
              required={true}
              labelShrink={false}
              options={[
                {
                  label: "Call",
                  value: "CALL",
                },
                {
                  label: "Collect signature",
                  value: "COLLECT_SIGNATURE",
                },
                {
                  label: "Download file",
                  value: "DOWNLOAD_FILE",
                },
                {
                  label: "Complete custom task",
                  value: "COMPLETE_CUSTOM_TASK",
                },
              ]}
              onChange={(e) => handleAddTask(e, id)}
            />
            {type === "CALL" && (
              <div>
                <div className="mt-3">
                  <BaseTextField
                    isDefaultField={true}
                    label="Phone"
                    labelShrink={false}
                    optional={false}
                    onChange={(e) =>
                      handleTaskChange(id, e.target.value, "phone")
                    }
                  />
                </div>
                <div className="mt-3">
                  <BaseTextField
                    isDefaultField={true}
                    label="Reason"
                    labelShrink={false}
                    optional={false}
                    onChange={(e) =>
                      handleTaskChange(id, e.target.value, "reason")
                    }
                  />
                </div>
              </div>
            )}
            {type === "COLLECT_SIGNATURE" && (
              <div>
                <div className="mt-3">
                  <BaseTextField
                    type="textarea"
                    isDefaultField={true}
                    label="From (e.g. on-site manager)"
                    labelShrink={false}
                    optional={false}
                    onChange={(e) =>
                      handleTaskChange(id, e.target.value, "signatureFrom")
                    }
                  />
                </div>
              </div>
            )}
            {type === "COMPLETE_CUSTOM_TASK" && (
              <div>
                <div className="mt-3">
                  <textarea
                    className="border w-full rounded-md h-32 resize-none p-4"
                    onChange={(e) =>
                      handleTaskChange(id, e.target.value, "description")
                    }
                  />
                </div>
              </div>
            )}
          </>
        )}

        <div className="mt-2">
          {emailsToNotify?.map((email) => (
            <div className="flex items-center mt-2">
              <div className="w-full">
                <BaseTextField
                  isDefaultField={false}
                  label="Email address for notification"
                  labelShrink={false}
                  optional={false}
                  placeholder="Email..."
                  onChange={(e) => handleEmailChange(email.id, e.target.value)}
                />
              </div>
              <div
                className="flex items-center justify-center shadow-md h-10 w-10 mt-7 ml-4 rounded-md cursor-pointer bg-white"
                onClick={() => handleRemoveItem(email.id)}
              >
                <i className="fa-solid fa-xmark"></i>
              </div>
            </div>
          ))}

          <div
            className="flex items-center cursor-pointer mt-3 inline-flex"
            onClick={handleAddEmail}
          >
            <i className="fa-solid fa-plus text-secondary"></i>
            <p className="text-secondary ml-1 text-sm">Add Notifications</p>
          </div>
        </div>
      </div>
    </div>
  );
}
