import React from "react";
import BaseCard from "../../../components/ui/BaseCard";

const ServiceFee = ({ payment, status }) => {
  return (
    <BaseCard className="pt-4 pb-6 px-56 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4 flex items-center justify-between pb-1">
        <h6 className="text-dark">Service fee</h6>
        {status === "WORKDONE" && (
          <h6 className="text-primary font-medium">
            ${payment?.serviceFee?.toFixed(2)}
          </h6>
        )}
      </div>

      <div className="flex items-center mb-2">
        <p className="min-w-40 font-normal">Service Fee</p>
        <div className="border-t border-dotted border-gray-500  w-full"></div>
        <p
          className={`flex items-center  ml-2 pl-4 py-1 rounded-md ${
            (status !== "WORKDONE" || status !== "APPROVED") &&
            "text-orange-600"
          }`}
        >
          {status === "WORKDONE" || status === "APPROVED"
            ? `$${payment?.serviceFee?.toFixed(2)}`
            : "Pending"}
        </p>
      </div>
      <div className="flex items-center mb-2">
        <p className="min-w-40 font-normal">Additional Fee</p>
        <div className="border-t border-dotted border-gray-500  w-full"></div>
        <p
          className={`flex items-center  ml-2 pl-4 py-1 rounded-md ${
            status !== "WORKDONE" && "text-orange-600"
          }`}
        >
          {status === "WORKDONE" ? "$0.00" : "Pending"}
        </p>
      </div>
    </BaseCard>
  );
};

export default ServiceFee;
