import React from "react";
import BaseModal from "../../../components/ui/BaseModal";
import BaseButton from "../../../components/ui/BaseButton";
import { useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useUpdateWorkOrder } from "../../../hooks/useUpdateWorkOrder";
import { handleGetLocation } from "../../../utils/helpers";
import { checkOut } from "../../../services/api/timeLog";
import dayjs from "dayjs";

const CheckOutModal = ({
  checkOutModalOpen,
  setCheckOutModalOpen,
  tasks,
  refetchUserAllowedAction,
}) => {
  const params = useParams();
  const { updateWorkOrder } = useUpdateWorkOrder();
  const {
    isLoading: isCheckOutLoading,
    error: isCheckOutError,
    data: checkOutTimeLogData,
    mutateAsync: checkOutMutation,
  } = useMutation({
    mutationFn: checkOut,
    enabled: false,
  });

  const handleCheckOut = async () => {
    try {
      // Get the user's location
      const location = await handleGetLocation();

      const task = tasks?.find((task) => task.taskType === "CHECK_OUT");
      // Create the payload with correct latitude and longitude
      let payload = {
        checkOutLatitude: location?.latitude,
        checkOutLongitude: location?.longitude, // Corrected key
        workOrderId: parseInt(params.id),
        taskId: task.completedTask.id,
      };

      // Update the task and check-in
      // await updateTaskApi(id, isCompleted);
      const response = await checkOutMutation(payload);

      // After successful task update and check-in, update the state
      // updateWorkOrderInStore(response, "WORKDONE");
      refetchUserAllowedAction();
      updateWorkOrder();
      setCheckOutModalOpen(false);
    } catch (error) {
      console.error("Error during check-in:", error);
      // Handle the error if necessary
    }
  };
  return (
    <div>
      <BaseModal
        open={checkOutModalOpen}
        close={() => {}}
        style={{ width: "600px" }}
      >
        <div className="container">
          <h6 className="text-dark border-bottom mb-4 pb-1">
            Confirm Check Out
          </h6>
          <p className="bg-primary text-white rounded py-2 px-3">
            You are checking out. Working hours will be calculated accordingly.
          </p>

          <div className="flex items-center justify-between mt-4">
            <p className="font-normal">Check Out Time</p>
            <p>
              {dayjs().format("dddd, MM/D/YYYY")} at {dayjs().format("h:mm A")}{" "}
              ({Intl.DateTimeFormat().resolvedOptions().timeZone})
            </p>
          </div>
        </div>
        <div
          className="flex items-center justify-center bg-white py-6 px-5 mt-3"
          style={{
            boxShadow: "5px 0px 14px 5px rgba(0, 0, 0, 0.07)",
          }}
        >
          <BaseButton
            className="py-[5px] px-2 bg-[#E8E8E8] text-dark"
            fullWidth={false}
            onClick={() => setCheckOutModalOpen(false)}
          >
            <p>Cancel</p>
          </BaseButton>
          <BaseButton
            disabled={isCheckOutLoading}
            isLoading={isCheckOutLoading}
            className="py-[5px] px-4 ml-4"
            color="primary"
            fullWidth={false}
            onClick={handleCheckOut}
          >
            Confirm Check Out
          </BaseButton>
        </div>
      </BaseModal>
      {/* <CloseoutNotesModal
        closeOutNotesModalOpen={closeOutNotesModalOpen}
        setCloseOutNotesModalOpen={setCloseOutNotesModalOpen}
      /> */}
    </div>
  );
};

export default CheckOutModal;
