import React, { useEffect, useState } from "react";
import BaseWrapper from "../../../components/ui/BaseWrapper";
import { useQuery } from "@tanstack/react-query";
import { fetchUserWorkOrders } from "../../../services/api/workOrder";
import dayjs from "dayjs";
import WorkOrderStatus from "../../../components/WorkOrderStatus";
import BaseDataTable from "../../../components/ui/BaseDataTable";
import FilterChip from "../../../components/FilterChip";
import { useNavigate } from "react-router-dom";
import { navigateWithReload, openInGoogleMaps } from "../../../utils/helpers";
import BaseDropdown from "../../../components/ui/BaseDropdown";
import BaseLoader from "../../../components/ui/BaseLoader";
import BaseTooltip from "../../../components/ui/BaseTooltip";
import BaseButton from "../../../components/ui/BaseButton";

const WorkOrderTable = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    status: [],
  });
  const [workOrders, setWorkOrders] = useState([]);
  const [openDropdownId, setOpenDropdownId] = useState(null); // Track open dropdown by work order ID
  const [statusCount, setStatusCount] = useState({});

  const {
    isLoading: isUserWorkOrderLoading,
    error: isWorkOrderError,
    data: workOrdersData,
  } = useQuery({
    queryKey: ["userWorkOrders"],
    queryFn: fetchUserWorkOrders,
  });

  if (isWorkOrderError?.response?.status === 401) {
    window.localStorage.clear();
    navigateWithReload("/company-signup");
  }

  const data = workOrders || [];
  const columnWidths = {
    ID: "120px",
    "Incident ID": "12%",
    Title: "296px",
    Location: "22%",
    Clients: "10%",
    Deliverables: "10%",
    Project: "10%",
    Payment: "15%",
    Schedule: "18%",
    Status: "14%",
    Messages: "10%",
    Template: "10%",
    "Check In": "10%",
    "Check Out": "10%",
    Created: "10%",
    "Finance Status": "10%",
    "Hours Logged": "10%",
    "Location Name": "10%",
    Manager: "10%",
    Provider: "10%",
    "Provider ID": "10%",
    "Service Date": "10%",
    Shipment: "10%",
    "Talent Type": "10%",
    "Type of Work": "10%",
    Requests: "10%",
    Counters: "10%",
    Routes: "10%",
    "Additional Column": "50%",
  };
  const COLUMNS = [
    {
      label: "ID",
      renderCell: (item) => (
        <p
          className={`table-font-size font-normal text-primary cursor-pointer`}
          onClick={() => handleNavigate(item.id)}
        >
          {item.id}
        </p>
      ),
      pinLeft: true,
      sort: { sortKey: "ID" },
    },
    {
      label: "Incident ID",
      renderCell: (item) => (
        <p className={`table-font-size truncate`}>{item.incidentId}</p>
      ),
    },
    {
      label: "Title",
      renderCell: (item) => (
        <>
          <p
            className={`table-font-size text-primary cursor-pointer truncate w-72`}
            onClick={() => handleNavigate(item.id)}
          >
            {item.title}
          </p>
        </>
      ),

      sort: { sortKey: "TITLE" },
    },
    {
      label: "Status",
      renderCell: (item) => (
        <div className="flex items-center">
          <WorkOrderStatus
            status={item.status}
            className="!py-[1.8px] !w-[70px] !text-[11px]"
          />
          {item.subStatus && (
            <i className="fa-solid fa-arrow-right-long mx-2 text-primary"></i>
          )}
          {item.subStatus && (
            <div className="ml-1">
              <WorkOrderStatus
                status={item.subStatus}
                className="!py-[1.5px] !w-[80px] !text-[9px]"
              />
            </div>
          )}
          {/* {item.status === "INPROGRESS" && (
            <div className="ml-1">
              <WorkOrderStatus
                status={
                  item?.tasks?.find((task) => task.taskType === "CHECK_OUT")
                    ?.completedTask?.isCompleted
                    ? "CHECK_OUT"
                    : "CHECK_IN"
                }
              />
            </div>
          )} */}
        </div>
      ),
      // sort: { sortKey: "STATUS" },
    },
    {
      label: "Requests",
      renderCell: (item) => (
        <p className={`table-font-size`}>{item.requests || 0}</p>
      ),
    },
    {
      label: "Counters",
      renderCell: (item) => (
        <p className={`table-font-size`}>{item.counters || 0}</p>
      ),
    },
    {
      label: "Routes",
      renderCell: (item) => (
        <p className={`table-font-size`}>{item.routes || 0}</p>
      ),
    },
    {
      label: "Payment",
      renderCell: (item) => (
        <div>
          <p className="text-primary text-xs">
            {item.paymentType === "HOURLY" && "hourly"}
          </p>

          {item.paymentType === "HOURLY" && (
            <p className={`table-font-size`}>
              {item?.minimumHours?.toFixed(2)}hours @ ${item.hourlyRate}/hr
            </p>
          )}
        </div>
      ),
    },
    {
      label: "Location",
      renderCell: (item) => (
        <div className="cursor-default">
          <BaseTooltip
            position="top"
            interactive={true}
            content={
              <div className="relative pb-2">
                <p className="text-white">{`${item.address.addressLine1}, ${item.address.country}`}</p>
                <i
                  class="fa-solid fa-diamond-turn-right text-secondary text-xl top-9 right-0 absolute cursor-pointer"
                  onClick={() =>
                    openInGoogleMaps(
                      item.address.latitude,
                      item.address.longitude
                    )
                  }
                ></i>
              </div>
            }
          >
            <p className="text-sm truncate max-w-72 overflow-hidden whitespace-nowrap">
              {`${item.address.addressLine1}, ${item.address.country}`}
            </p>
          </BaseTooltip>
        </div>
      ),
      // sort: { sortKey: "LOCATION" },
    },
    {
      label: "Schedule",
      renderCell: (item) => (
        <div>
          <p className={`text-primary text-xs`}>
            {item.scheduleType === "HARD_START"
              ? "Hard Time"
              : item.scheduleType === "WORK_BETWEEN"
              ? "Work Between"
              : ""}
          </p>
          <p className={`table-font-size`}>
            {dayjs(item.orderStartDate).format("MM/DD/YYYY")}

            <span> at </span>

            {dayjs(item.orderStartTime).format("hh:mm A")}
            <span> ({item.timeZone})</span>
          </p>
        </div>
      ),
    },

    {
      label: "Messages",
      renderCell: (item) => (
        <p className={``}>{item?._count?.messages || 0} Msgs</p>
      ),
    },

    {
      label: "Manager",
      renderCell: (item) =>
        item?.workOrderManager && (
          <p>{`${item?.workOrderManager?.firstName} ${item?.workOrderManager?.lastName}`}</p>
        ),
    },
    {
      label: "Provider",
      renderCell: (item) => (
        <p className={`text-primary`}>
          {item?.offers[0] &&
            `${item?.offers[0]?.user.firstName} ${item?.offers[0]?.user.lastName}`}
        </p>
      ),
    },
    {
      label: "Provider Mobile",
      renderCell: (item) => (
        <p className={`text-primary`}>
          {item?.offers[0] && `${item?.offers[0]?.user.phoneNumber}`}
        </p>
      ),
    },

    {
      label: "Clients",
      renderCell: (item) => (
        <p className={`table-font-size`}>
          {/* {`${item.address.addressLine1}, ${item.address.country}`} */}
        </p>
      ),
    },
    {
      label: "Project",
      renderCell: (item) => (
        <p className={`table-font-size`}>
          {/* {`${item.address.addressLine1}, ${item.address.country}`} */}
        </p>
      ),
    },
    {
      label: "Template",
      renderCell: (item) => (
        <p className={`table-font-size`}>
          {/* {`${item.address.addressLine1}, ${item.address.country}`} */}
        </p>
      ),
    },

    {
      label: "Deliverables",
      renderCell: (item) => (
        <p className={`table-font-size`}>
          {/* {`${item.address.addressLine1}, ${item.address.country}`} */}
        </p>
      ),
    },

    {
      label: "Check In",
      renderCell: (item) => (
        <p className={``}>
          {item?.timeLogs[0]?.checkInTime &&
            dayjs(item?.timeLogs[0]?.checkInTime).format("hh:mm A")}
        </p>
      ),
    },
    {
      label: "Check Out",
      renderCell: (item) => (
        <p className={``}>
          {item?.timeLogs[item.timeLogs.length - 1]?.checkOutTime &&
            dayjs(
              item?.timeLogs[item.timeLogs.length - 1]?.checkOutTime
            ).format("hh:mm A")}
        </p>
      ),
    },
    {
      label: "Hours Logged",
      renderCell: (item) => (
        <p className={``}>{item?.hoursWorked?.toFixed(2) || 0.0} </p>
      ),
    },
    {
      label: "Created",
      renderCell: (item) => (
        <p className={``}>
          {dayjs(item?.createdAt).format("MM/DD/YYYY")} at{" "}
          {dayjs(item?.createdAt).format("hh:mm A")}
        </p>
      ),
    },
    {
      label: "Finance Status",
      renderCell: (item) => <p className={``}>Pre-Fund</p>,
    },

    {
      label: "Location Name",
      renderCell: (item) => <p className={``}>{item?.address?.displayName}</p>,
    },
    {
      label: "Provider ID",
      renderCell: (item) => (
        <p className={`text-primary`}>
          {item?.offers[0] && `${item?.offers[0]?.user.id}`}
        </p>
      ),
    },
    {
      label: "Service Date",
      renderCell: (item) => <p className={``}></p>,
    },
    {
      label: "Shipment",
      renderCell: (item) => <p className={``}></p>,
    },
    {
      label: "Talent Type",
      renderCell: (item) => <p className={``}></p>,
    },
    {
      label: "Type of Work",
      renderCell: (item) => (
        <p className="table-font-size">{`${
          item?.category?.category || "not specified"
        }`}</p>
      ),
    },
    {
      label: <i className="fa-solid fa-gear text-[20px] mt-1"></i>,
      renderCell: (item) => (
        <div className="relative overflow-visible">
          <i
            className="fa-solid fa-ellipsis text-[16px] cursor-pointer"
            onClick={() => toggleDropdown(item.id)}
          ></i>
          {openDropdownId === item.id && (
            <BaseDropdown
              key={item.id}
              open={true}
              className="w-[186px]  h-auto top-[6px] left-[-190px] shadow-none"
              style={{
                boxShadow: "0 1px 10px rgba(0, 0, 0, 0.2)",
              }}
            >
              <div
                className="hover:bg-[#F6F6F6] py-3 px-1 cursor-pointer border-b rounded-t-lg"
                onClick={() => navigate(`/work-orders/edit/summary/${item.id}`)}
              >
                <p className={`table-font-size capitalize`}>Edit</p>
              </div>
              <div className="hover:bg-[#F6F6F6] py-3 px-1 cursor-pointer border-b">
                <p className={`table-font-size capitalize`}>Publish</p>
              </div>
              <div className="hover:bg-[#F6F6F6] py-3 px-1 cursor-pointer rounded-b-lg">
                <p className={`table-font-size capitalize`}>Cancel</p>
              </div>
            </BaseDropdown>
          )}
        </div>
      ),
      pinRight: true,
    },
  ];

  const handleNavigate = (id) => {
    navigate(`/find-job/${id}`);
  };

  const isTodayOrTomorrow = (date) => {
    const today = dayjs().startOf("day");
    const tomorrow = today.add(1, "day");
    const itemDate = dayjs(date).startOf("day");
    return itemDate.isSame(today, "day") || itemDate.isSame(tomorrow, "day");
  };
  const getStatusCounts = () => {
    if (!workOrdersData?.data.workOrders) return {};

    return workOrdersData?.data.workOrders.reduce((acc, workOrder) => {
      const { status, orderStartDate } = workOrder;
      if (!status) return acc;

      if (isTodayOrTomorrow(orderStartDate)) {
        acc["INFLIGHT"] = (acc["INFLIGHT"] || 0) + 1;
      }
      acc[status] = (acc[status] || 0) + 1;

      return acc;
    }, {});
  };

  //this will initially run to set the work orders data fetched from API
  useEffect(() => {
    setWorkOrders(workOrdersData?.data.workOrders);
    setStatusCount(getStatusCounts());
  }, [workOrdersData]);

  //this is filter the work order based on current filter selected without API call
  useEffect(() => {
    if (!filters.status || filters.status.length === 0) {
      setWorkOrders(workOrdersData?.data.workOrders);
    } else if (filters.status.includes("INFLIGHT")) {
      const filteredWorkOrders = filterInFlightWorkOrders();
      setWorkOrders(filteredWorkOrders);
    } else {
      const filteredWorkOrders = workOrdersData?.data.workOrders.filter(
        (workOrder) => filters.status.includes(workOrder.status)
      );
      setWorkOrders(filteredWorkOrders);
    }
  }, [filters]);

  const filterInFlightWorkOrders = () => {
    const today = dayjs().startOf("day");
    const tomorrow = today.add(1, "day");

    const filteredWorkOrders = workOrdersData?.data.workOrders.filter(
      (workOrder) => {
        const workOrderDate = dayjs(workOrder.orderStartDate).startOf("day");
        return (
          workOrderDate.isSame(today, "day") ||
          workOrderDate.isSame(tomorrow, "day")
        );
      }
    );

    return filteredWorkOrders;
  };

  const toggleDropdown = (id) => {
    if (openDropdownId === id) {
      setOpenDropdownId(null); // Close the dropdown if it's already open
    } else {
      setOpenDropdownId(id); // Open the dropdown for the selected row
    }
  };

  return (
    <>
      <BaseLoader isLoading={isUserWorkOrderLoading} />
      <BaseWrapper>
        <div className="bg-white rounded-lg p-5 shadow-md my-4 sticky top-10 z-10">
          <div className="flex">
            <div className="flex items-center border-r-[1px] border-[#8C8C8C] pr-5">
              <i className="fa-solid fa-circle-plus text-secondary text-[22px]"></i>
              <p className="text-secondary ml-2 ">Add Filter</p>
            </div>
            {/* <div className="flex items-center pl-5">
            <p className="font-medium">
              Assigned Provider:{" "}
              <span className="font-light">Samuel Nyandoro</span>
            </p>
            <i className="ml-3 mb-[2px] fa-solid fa-chevron-down"></i>
          </div> */}
          </div>
        </div>

        <div className="my-4 flex ">
          <div className="mr-5">
            <FilterChip
              filter="Draft"
              count={statusCount.DRAFT ? statusCount.DRAFT : 0}
              active={filters.status.includes("DRAFT") ? true : false}
              onClick={() => setFilters({ status: ["DRAFT"] })}
            />
          </div>
          <div className="mr-5">
            <FilterChip
              filter="Published\Routed"
              count={statusCount.PUBLISHED ? statusCount.PUBLISHED : 0}
              active={filters.status.includes("PUBLISHED") ? true : false}
              onClick={() => setFilters({ status: ["PUBLISHED"] })}
            />
          </div>
          <div className="mr-5">
            <FilterChip
              filter="Assigned"
              count={statusCount.ASSIGNED ? statusCount.ASSIGNED : 0}
              active={filters.status.includes("ASSIGNED") ? true : false}
              onClick={() => setFilters({ status: ["ASSIGNED"] })}
            />
          </div>
          <div className="mr-5">
            <FilterChip
              filter="Inprogress"
              count={statusCount.INPROGRESS ? statusCount.INPROGRESS : 0}
              active={filters.status.includes("INPROGRESS") ? true : false}
              onClick={() => setFilters({ status: ["INPROGRESS"] })}
            />
          </div>

          <div className="mr-5">
            <FilterChip
              filter="Work Done"
              count={statusCount.WORKDONE ? statusCount.WORKDONE : 0}
              active={filters.status.includes("WORKDONE") ? true : false}
              onClick={() => setFilters({ status: ["WORKDONE"] })}
            />
          </div>
          <div className="mr-5">
            <FilterChip
              filter="Approved/Paid"
              count={statusCount.APPROVED ? statusCount.APPROVED : 0}
              active={filters.status.includes("APPROVED") ? true : false}
              onClick={() => setFilters({ status: ["APPROVED"] })}
            />
          </div>
          <div className="mr-5">
            <FilterChip
              filter="Issue"
              count={statusCount.INCOMPLETE ? statusCount.INCOMPLETE : 0}
              active={filters.status.includes("INCOMPLETE") ? true : false}
              onClick={() => setFilters({ status: ["INCOMPLETE"] })}
            />
          </div>
          <FilterChip
            filter="All"
            count={workOrdersData?.data.workOrders?.length}
            active={filters.status.includes("") ? true : false}
            onClick={() => setFilters({ status: "" })}
          />
        </div>

        <BaseDataTable
          columns={COLUMNS}
          nodes={data}
          columnWidths={columnWidths}
        />
      </BaseWrapper>
    </>
  );
};

export default WorkOrderTable;
