import React from "react";
import BaseCard from "../../../components/ui/BaseCard";

const Tax = ({ payment, status }) => {
  return (
    <BaseCard className="pt-4 pb-6 px-56 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4 flex items-center justify-between pb-1">
        <h6 className="text-dark">Tax</h6>
      </div>

      <div className="flex items-center mb-2">
        <p className="min-w-40 font-normal">
          VAT/GST <span className="text-xs">(if any)</span>
        </p>
        <div className="border-t border-dotted border-gray-500  w-full"></div>
        <p className=" flex items-center  ml-2 px-4 py-1 rounded-md text-red-600">
          $0.00
        </p>
      </div>
    </BaseCard>
  );
};

export default Tax;
