import React, { useState } from "react";
import BaseModal from "../../../components/ui/BaseModal";
import BaseButton from "../../../components/ui/BaseButton";
import BaseCard from "../../../components/ui/BaseCard";
import BaseRating from "../../../components/ui/BaseRating";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import { createFeedback } from "../../../services/api/feedback";
import { showToast } from "../../../store/toast/toastSlice";

const EngineerPublicFeedbackModal = ({
  engineerPublicFeedbackModalOpen,
  setEngineerPublicFeedbackModalOpen,
}) => {
  const dispatch = useDispatch();
  const workOrderDetail = useSelector(
    (state) => state.workOrder.workOrderDetails
  );
  const [rating, setRating] = useState(null);
  const [comment, setComment] = useState("");
  const {
    isLoading: isFeedbackLoading,
    error: isFeedbackError,
    status,
    mutateAsync: createFeedbackMutation,
  } = useMutation({
    mutationFn: createFeedback,
  });

  const handleRatingChange = (e, newValue) => {
    setRating(newValue);
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSubmitButton = async () => {
    try {
      const payload = {
        rating,
        comment,
        workOrderId: workOrderDetail.id,
        isPrivate: false,
      };

      const feedbackResponse = await createFeedbackMutation(payload);

      setEngineerPublicFeedbackModalOpen(false);
      setRating(null);
      setComment("");
    } catch (error) {
      dispatch(
        showToast({
          message: "Feedback | something went wrong",
          type: "error",
        })
      );
    }
  };

  const handleCancelButton = async () => {
    try {
      const payload = {
        workOrderId: workOrderDetail.id,
        isPrivate: false,
        isSkip: true,
      };

      // const feedbackResponse = await createFeedbackMutation(payload);

      setEngineerPublicFeedbackModalOpen(false);
    } catch (error) {
      dispatch(
        showToast({
          message: "Public feedback is skipped",
          type: "error",
        })
      );
    }
  };

  return (
    <BaseModal
      className="-top-60"
      open={engineerPublicFeedbackModalOpen}
      close={() => {}}
      style={{ width: "800px" }}
    >
      <div className="container px-12">
        <div className="flex items-center  border-bottom mb-4 pb-1">
          <h5 className="text-dark">Public rating & review</h5>
        </div>

        <div>
          <h6>
            <span className="text-primary">W/O 14125773 </span>|{" "}
            {workOrderDetail.title}
          </h6>
          <h6 className="font-normal">
            {workOrderDetail?.createdBy?.company?.name}
          </h6>
        </div>

        <BaseCard className="p-4 mt-5 bg-light" elevation={0}>
          <p>
            Rate Your experience with buyer. This will be visible to everyone on
            Marketplace including the buyer being rated.
          </p>
          <div className="flex items-center justify-between mx-40 my-2">
            <p>Unsatisfied</p>
            <BaseRating
              size="large"
              readOnly={false}
              rating={rating}
              onChange={(event, newValue) =>
                handleRatingChange(event, newValue)
              }
            />
            <p>Excellent</p>
          </div>

          <textarea
            placeholder="Add Comment"
            className="w-full rounded-md h-28 resize-none p-4 mt-1 focus:outline-none focus:ring-1 focus:ring-orange-400 text-xs font-[300] "
            onChange={(e) => handleCommentChange(e)}
          ></textarea>
        </BaseCard>
      </div>
      <div
        className="flex items-center justify-center bg-white py-6 px-5 mt-5"
        style={{
          boxShadow: "5px 0px 14px 5px rgba(0, 0, 0, 0.07)",
        }}
      >
        <BaseButton
          className="py-[5px] px-2 bg-[#E8E8E8] text-[#686868]"
          fullWidth={false}
          onClick={handleCancelButton}
        >
          Cancel
        </BaseButton>
        <BaseButton
          className="py-[5px] px-4 ml-4 w-[100px]"
          color="primary"
          fullWidth={false}
          onClick={handleSubmitButton}
        >
          Submit
        </BaseButton>
      </div>
    </BaseModal>
  );
};

export default EngineerPublicFeedbackModal;
