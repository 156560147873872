import React from "react";
import useAuth from "./hooks/useAuth";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { navigateWithReload } from "./utils/helpers";

function isModuleAllowed(userModules, allowedModules) {
  // Extract module names from allowedModules
  const allowedModuleNames = allowedModules.map((mod) => mod.module);

  // Check if any userModule exists in allowedModuleNames
  return userModules?.some((module) => allowedModuleNames.includes(module));
}

const RequireAuth = ({ allowedModules }) => {
  const { userModules, isAuthenticated, userPermissions } = useAuth();
  const location = useLocation();

  const result = isModuleAllowed(userModules, allowedModules);

  if (isAuthenticated) {
    if (result) {
      return <Outlet />;
    } else {
      return <Navigate to="/unauthorized" state={{ from: location }} replace />;
    }
  } else {
    navigateWithReload("company-signup");
  }
};

export default RequireAuth;
