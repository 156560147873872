import React from "react";
import { GoogleMap, MarkerF } from "@react-google-maps/api";
import "./style.css";

const containerStyle = {
  width: "100%",
  height: "270px",
  borderRadius: "10px",
};

const options = {
  mapTypeControl: false,
  zoomControl: false,
  fullscreenControl: false,
};
function Map({ latitude, longitude }) {
  const center = {
    lat: latitude,
    lng: longitude,
  };

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={18}
      options={options}
    >
      <MarkerF position={center} />
    </GoogleMap>
  );
}

export default React.memo(Map);
