import React, { useState } from "react";
import BaseModal from "../../../components/ui/BaseModal";
import BaseButton from "../../../components/ui/BaseButton";
import BaseCard from "../../../components/ui/BaseCard";

const FeedbackSuccessModal = ({
  feedbackSuccessModalOpen,
  setFeedbackSuccessModalOpen,
  setEngineerPublicFeedbackModalOpen,
}) => {
  return (
    <BaseModal
      className="-top-60"
      open={feedbackSuccessModalOpen}
      close={() => {}}
      style={{ width: "700px" }}
    >
      <div className="container ">
        <div className="flex flex-col items-center my-8">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="158"
            height="158"
            viewBox="0 0 158 158"
            fill="none"
          >
            <circle cx="79" cy="79" r="79" fill="#E7F9F1" />
            <path
              d="M79 135C93.8521 135 108.096 129.1 118.598 118.598C129.1 108.096 135 93.8521 135 79C135 64.1479 129.1 49.9041 118.598 39.402C108.096 28.9 93.8521 23 79 23C64.1479 23 49.9041 28.9 39.402 39.402C28.9 49.9041 23 64.1479 23 79C23 93.8521 28.9 108.096 39.402 118.598C49.9041 129.1 64.1479 135 79 135ZM103.719 68.7188L75.7188 96.7188C73.6625 98.775 70.3375 98.775 68.3031 96.7188L54.3031 82.7188C52.2469 80.6625 52.2469 77.3375 54.3031 75.3031C56.3594 73.2688 59.6844 73.2469 61.7188 75.3031L72 85.5844L96.2812 61.2812C98.3375 59.225 101.662 59.225 103.697 61.2812C105.731 63.3375 105.753 66.6625 103.697 68.6969L103.719 68.7188Z"
              fill="#1DA881"
            />
          </svg>
          <h5 className="text-center mt-6">
            your Private Feedback has been saved
          </h5>
          <p className="mt-2 text-center px-32">
            Would you like to provide a public review of this provider to share
            with others on Tech Domain?
          </p>
        </div>
      </div>
      <div
        className="flex items-center justify-center bg-white py-6 px-5"
        style={{
          boxShadow: "5px 0px 14px 5px rgba(0, 0, 0, 0.07)",
        }}
      >
        <BaseButton
          className="py-[5px] px-2 bg-[#E8E8E8] text-primary"
          fullWidth={false}
          onClick={() => setFeedbackSuccessModalOpen(false)}
        >
          No Thanks
        </BaseButton>
        <BaseButton
          className="py-[5px] px-4 ml-4 w-[100px]"
          color="primary"
          fullWidth={false}
          onClick={() => {
            setFeedbackSuccessModalOpen(false);
            setEngineerPublicFeedbackModalOpen(true);
          }}
        >
          ok
        </BaseButton>
      </div>
    </BaseModal>
  );
};

export default FeedbackSuccessModal;
