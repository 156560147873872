import React, { useState } from "react";
import BaseModal from "../../../components/ui/BaseModal";
import BaseButton from "../../../components/ui/BaseButton";
import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { showToast } from "../../../store/toast/toastSlice";

import { useUpdateWorkOrder } from "../../../hooks/useUpdateWorkOrder";
import { updateTask } from "../../../services/api/task";

import dayjs from "dayjs";

const OnTheWayModal = ({
  onTheWayModalOpen,
  setOnTheWayModalOpen,
  tasks,
  refetchUserAllowedAction,
}) => {
  const dispatch = useDispatch();
  const { updateWorkOrder } = useUpdateWorkOrder();
  const {
    isLoading: isTaskUpdateLoading,
    error: isTaskUpdateError,
    data: updatedTaskData,
    mutateAsync: updateTaskMutation,
  } = useMutation({
    mutationFn: updateTask,
    enabled: false,
  });

  const handleOnTheWay = async () => {
    const task = tasks?.find((task) => task.taskType === "ON_THE_WAY");
    try {
      let payload = {
        id: task.completedTask?.id,
        isCompleted: true,
      };

      const updatedTask = await updateTaskMutation(payload);

      updateWorkOrder();
      refetchUserAllowedAction();
      setOnTheWayModalOpen(false);
    } catch (error) {
      dispatch(
        showToast({
          message: "Cannot mark On the Way at the moment",
          type: "error",
        })
      );
    }
  };
  return (
    <BaseModal
      open={onTheWayModalOpen}
      close={() => {
        setOnTheWayModalOpen(false);
      }}
      style={{ width: "600px" }}
    >
      <div className="container">
        <h6 className="text-dark border-bottom mb-4 pb-1">On the Way</h6>
        <p className="bg-primary text-white rounded py-2 px-3">
          It will change the work order status to on the way & notify buyer.
        </p>

        <div className="flex items-center justify-between mt-4">
          <p className="font-normal">Marked at</p>
          <p>
            {dayjs().format("dddd, MM/D/YYYY")} at {dayjs().format("h:mm A")} (
            {Intl.DateTimeFormat().resolvedOptions().timeZone})
          </p>
        </div>
      </div>
      <div
        className="flex items-center justify-center bg-white py-6 mt-3"
        style={{
          boxShadow: "5px 0px 14px 5px rgba(0, 0, 0, 0.07)",
        }}
      >
        <BaseButton
          className="py-[5px] px-2 bg-[#E8E8E8] text-dark"
          fullWidth={false}
          onClick={() => setOnTheWayModalOpen(false)}
        >
          <p>Cancel</p>
        </BaseButton>
        <BaseButton
          /* disabled={isCheckInLoading}
          isLoading={isCheckInLoading} */
          className="py-[5px] px-4 ml-4"
          color="primary"
          fullWidth={false}
          onClick={handleOnTheWay}
        >
          On the Way
        </BaseButton>
      </div>
    </BaseModal>
  );
};

export default OnTheWayModal;
