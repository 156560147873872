import React, { useEffect, useState } from "react";
import {
  addQualificationsToUser,
  fetchUserQualifications,
} from "../../../services/api/user";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { showToast } from "../../../store/toast/toastSlice";
import useAuth from "../../../hooks/useAuth";

const QualificationMatcher = ({
  workOrderQualifications,
  setIsAllQualificationsPassed,
}) => {
  const { authRole } = useAuth();
  const dispatch = useDispatch();
  const [formattedQualfications, setFormattedQualfications] = useState([]);
  const {
    loading: isUserQualificationsLoading,
    error: isUserQualificationsError,
    refetch: refetchUserQualifications,
    data: userQualificationsData,
  } = useQuery({
    queryKey: ["userQualifications"],
    queryFn: fetchUserQualifications,
  });

  const {
    isLoading: isUserQualificationAddedLoading,
    error: isUserQualificationAddedError,
    mutateAsync: addQualificationsToUserMutation,
  } = useMutation({
    mutationFn: addQualificationsToUser,
    enabled: false,
  });

  useEffect(() => {
    const matchedQualifications = matchQualifications(
      workOrderQualifications,
      userQualificationsData?.data
    );
    setFormattedQualfications(matchedQualifications);

    if (setIsAllQualificationsPassed) {
      // Check if all qualifications are passed
      const allPassed = matchedQualifications?.every((q) => q.status);

      setIsAllQualificationsPassed(allPassed);
    }
  }, [userQualificationsData]);

  // Match qualifications
  const matchQualifications = (workOrderQualifications, userQualifications) => {
    const userQualificationIds = new Set(userQualifications?.map((q) => q.id));

    return workOrderQualifications?.map((qualification) => ({
      ...qualification,
      status: userQualificationIds.has(qualification.id) ? true : false,
    }));
  };

  const addMissingQualification = async (qualificationIds) => {
    try {
      const payload = {
        qualificationIds: [qualificationIds],
      };
      await addQualificationsToUserMutation(payload);
      refetchUserQualifications();
      dispatch(
        showToast({
          message: "Missing qualification added.",
          type: "success",
        })
      );
    } catch (error) {
      dispatch(
        showToast({
          message: error?.response?.data?.message,
          type: "error",
        })
      );
    }
  };
  return (
    <div class="w-full">
      <table className="">
        <thead class="table w-full">
          <tr class="bg-primary text-white w-full">
            <th class="px-4 py-2 w-1/6 text-left">
              <p className="text-white">Type</p>
            </th>
            <th class="px-4 py-2 w-2/6 text-left">
              <p className="text-white">Qualification</p>
            </th>
            {authRole === "FREELANCER" && (
              <th class="px-4 py-2 w-1/6 text-left">
                <p className="text-white"> Status</p>
              </th>
            )}
            {authRole === "FREELANCER" && (
              <th class="px-4 py-2 w-2/5 text-left">
                <p className="text-white"> Action</p>
              </th>
            )}
          </tr>
        </thead>
        <tbody className="table">
          {formattedQualfications?.map((qualification) => (
            <tr
              className="odd:bg-white even:bg-gray-100 border-b "
              key={qualification.id}
            >
              <td class="px-4 py-2 w-1/6">
                <p className="text-primary cursor-default	">
                  {qualification?.qualificationType?.name}
                </p>
              </td>
              <td class="px-4 py-2 w-2/6">
                <p className="cursor-default">{qualification?.name}</p>
              </td>
              {authRole === "FREELANCER" && (
                <td class="px-4 py-2 w-1/6">
                  <p
                    className={`cursor-default ${
                      qualification.status ? "text-green-700" : "text-red-600"
                    }`}
                  >
                    {qualification.status ? "Passed" : "Missing"}
                  </p>
                </td>
              )}
              {authRole === "FREELANCER" && (
                <td class="px-4 py-2 w-2/5">
                  {!qualification.status && (
                    <p
                      className="text-primary cursor-pointer"
                      onClick={() => addMissingQualification(qualification.id)}
                    >
                      Add Equipment to profile
                    </p>
                  )}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default QualificationMatcher;
