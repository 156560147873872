import React from "react";

const LocationCard = ({ location }) => {
  const openGooglMapLocation = () => {
    window.open(
      "https://maps.google.com?q=" +
        location?.latitude +
        "," +
        location?.longitude
    );
  };

  return (
    <div className="shadow-md rounded-md py-3 px-4 min-h-24 w-full bg-white">
      <div className="flex">
        <i className="fa-solid fa-location-dot  text-3xl text-primary"></i>
        <div className="ml-2 mt-1">
          <p className="font-normal text-dark text-sm">
            Location{location?.displayName && `: ${location?.displayName}`}
          </p>

          <p
            className="text-primary cursor-pointer"
            onClick={openGooglMapLocation}
          >
            {location?.addressLine1}
          </p>
        </div>
      </div>
    </div>
  );
};

export default LocationCard;
