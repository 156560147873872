import React, { useState } from "react";
import BaseCard from "../../../components/ui/BaseCard";
import useAuth from "../../../hooks/useAuth";
import BaseRating from "../../../components/ui/BaseRating";
import BaseModal from "../../../components/ui/BaseModal";
import EngineerPrivateFeedbackModal from "../EngineerPrivateFeedbackModal";

const Overview = ({ workOrderDetail }) => {
  const { authRole } = useAuth();
  const [
    engineerPrivateFeedbackModalOpen,
    setEngineerPrivateFeedbackModalOpen,
  ] = useState(false);

  return (
    <>
      <BaseCard className="pt-4 pb-6 px-16 mt-1 rounded-lg" elevation={1}>
        <div className="border-bottom mb-4 flex items-center pb-1">
          <h6 className="text-dark">Overview</h6>
          {authRole === "CLIENT" && <i className="fa-solid fa-pencil ml-3"></i>}
        </div>
        <div className="grid grid-cols-3 gap-3">
          <div className="flex mb-2 bg-light rounded-md py-1.5 px-8 mr-16">
            <p className="font-normal w-36">Company: </p>
            <p className="font-light">
              {workOrderDetail?.createdBy?.company?.name}
            </p>
          </div>
          <div className="flex mb-2 bg-light rounded-md py-1.5 px-8 mr-16">
            <p className="font-normal w-36">Client: </p>
            <p className="font-light">No client specified</p>
          </div>
          {workOrderDetail?.feedback ? (
            <div
              className="flex mb-2 bg-light rounded-md py-1.5 px-8 mr-16 cursor-pointer"
              onClick={() => setEngineerPrivateFeedbackModalOpen(true)}
            >
              <p className="font-normal w-36">Feedback: </p>
              <BaseRating rating={workOrderDetail?.feedback?.rating} />
            </div>
          ) : (
            <div></div>
          )}

          <div className="flex mb-2 bg-light rounded-md py-1.5 px-8 mr-16">
            <p className="font-normal w-36">Project:</p>
            <p className="font-light">No project specified</p>
          </div>
          <div className="flex mb-2 bg-light rounded-md py-1.5 px-8 mr-16">
            <p className="font-normal w-36">Talent Type:</p>
            <p className="font-light">Tech Domain</p>
          </div>
        </div>
      </BaseCard>
      {engineerPrivateFeedbackModalOpen && (
        <EngineerPrivateFeedbackModal
          engineerPrivateFeedbackModalOpen={engineerPrivateFeedbackModalOpen}
          setEngineerPrivateFeedbackModalOpen={
            setEngineerPrivateFeedbackModalOpen
          }
          previewFeedback={workOrderDetail?.feedback}
        />
      )}
    </>
  );
};

export default Overview;
