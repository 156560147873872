import React, { useEffect, useState } from "react";
import BaseModal from "../../../components/ui/BaseModal";
import BaseButton from "../../../components/ui/BaseButton";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../../store/toast/toastSlice";
import { useUpdateWorkOrder } from "../../../hooks/useUpdateWorkOrder";

import BaseCard from "../../../components/ui/BaseCard";
import BaseRating from "../../../components/ui/BaseRating";
import BaseSwitch from "../../../components/ui/BaseSwitch";
import BaseEditor from "../../../components/ui/BaseEditor";
import { createFeedback } from "../../../services/api/feedback";

const FeedbacksLikeMostTexts = [
  {
    text: "Clear Communication",
    isSelected: false,
  },
  {
    text: "Timely Payment",
    isSelected: false,
  },

  {
    text: "Profesionalism",
    isSelected: false,
  },
  {
    text: "Respectful Interaction",
    isSelected: false,
  },
  {
    text: "Flexibility",
    isSelected: false,
  },
  {
    text: "Well Organized",
    isSelected: false,
  },

  {
    text: "Collaborative Attitude",
    isSelected: false,
  },
  {
    text: "Defined Project Scope",
    isSelected: false,
  },
  {
    text: "Working Relationship",
    isSelected: false,
  },
];

const FeedbackProblemTexts = [
  {
    text: "Poor Communication",
    isSelected: false,
  },
  {
    text: "Delayed Feedback",
    isSelected: false,
  },
  {
    text: "Lack of Professionalism",
    isSelected: false,
  },
  {
    text: "Unrealistic Deadline",
    isSelected: false,
  },
  {
    text: "Micromanagement",
    isSelected: false,
  },
  {
    text: "Delayed Payment",
    isSelected: false,
  },
  {
    text: "Unclear Requirements",
    isSelected: false,
  },
  {
    text: "Disrespect for time",
    isSelected: false,
  },
  {
    text: "Frequent Scope Changes",
    isSelected: false,
  },
];

const EngineerPrivateFeedbackModal = ({
  engineerPrivateFeedbackModalOpen,
  setEngineerPrivateFeedbackModalOpen,
  setFeedbackSuccessModalOpen,
  refetchUserAllowedAction,
  setEngineerPublicFeedbackModalOpen,
  previewFeedback,
}) => {
  const dispatch = useDispatch();
  const { updateWorkOrder } = useUpdateWorkOrder();
  const workOrderDetail = useSelector(
    (state) => state.workOrder.workOrderDetails
  );
  const [isCommentAdded, setIsCommentAdded] = useState(false);
  const [problemFeedbacks, setProblemFeedbacks] =
    useState(FeedbackProblemTexts);
  const [mostLikedFeedbacks, setMostLikedFeedbacks] = useState(
    FeedbacksLikeMostTexts
  );
  const [rating, setRating] = useState(null);
  const [comment, setComment] = useState("");

  useEffect(() => {
    if (previewFeedback) {
      setComment(previewFeedback.comment);
      setRating(previewFeedback.rating);
      const filteredFeedbackProblems = FeedbackProblemTexts.filter((problem) =>
        previewFeedback.problemFaced.includes(problem.text)
      );

      const filteredMostLiked = FeedbacksLikeMostTexts.filter((liked) =>
        previewFeedback.mostLiked.includes(liked.text)
      );

      setProblemFeedbacks(filteredFeedbackProblems);
      setMostLikedFeedbacks(filteredMostLiked);
    }
  }, []);

  const {
    isLoading: isFeedbackLoading,
    error: isFeedbackError,
    status,
    mutateAsync: createFeedbackMutation,
  } = useMutation({
    mutationFn: createFeedback,
  });

  const handleRatingChange = (e, newValue) => {
    setRating(newValue);
  };

  const handleCommentChange = (body) => {
    setComment(body);
  };

  const handleMostLikedFeedbackClick = (text) => {
    if (previewFeedback) return;

    setMostLikedFeedbacks((prevMostLiked) =>
      prevMostLiked.map((item) =>
        item.text === text ? { ...item, isSelected: !item.isSelected } : item
      )
    );
  };

  const handleProblemFacedFeedbackClick = (text) => {
    if (previewFeedback) return;

    setProblemFeedbacks((prevProblemFaced) =>
      prevProblemFaced.map((item) =>
        item.text === text ? { ...item, isSelected: !item.isSelected } : item
      )
    );
  };

  const handleSubmitButton = async () => {
    try {
      const selectedMostLikedFeedbacks = mostLikedFeedbacks
        .filter((feedback) => feedback.isSelected)
        .map((feedback) => feedback.text);

      const selectedProblemFacedFeedbacks = problemFeedbacks
        .filter((feedback) => feedback.isSelected)
        .map((feedback) => feedback.text);

      const payload = {
        rating,
        comment,
        problemFaced: selectedProblemFacedFeedbacks,
        mostLiked: selectedMostLikedFeedbacks,
        workOrderId: workOrderDetail.id,
        isPrivate: true,
      };

      const feedbackResponse = await createFeedbackMutation(payload);

      setFeedbackSuccessModalOpen(true);
      setEngineerPrivateFeedbackModalOpen(false);
      setRating(null);
      setComment("");
      refetchUserAllowedAction();
    } catch (error) {
      dispatch(
        showToast({
          message: "Feedback | something went wrong",
          type: "error",
        })
      );
    }
  };

  const handleCloseButton = async () => {
    setEngineerPrivateFeedbackModalOpen(false);
  };

  return (
    <BaseModal
      open={engineerPrivateFeedbackModalOpen}
      close={() => {}}
      style={{ width: "800px" }}
    >
      <div className="container px-12">
        <div className="flex items-center  border-bottom mb-4 pb-1">
          <h5 className="text-dark">Private Feedback</h5>
          <i className="fa-solid fa-lock ml-2 text-dark"></i>
        </div>

        <div className="flex items-center">
          <img
            src="https://axeray.com/wp-content/uploads/2023/10/3.png"
            className="h-12"
          />
          <div className="ml-2">
            <h6 className="text-primary capitalize">
              {workOrderDetail?.title}
            </h6>
            <p>{workOrderDetail?.createdBy?.company?.name}</p>
          </div>
        </div>

        {!previewFeedback && (
          <p className="mt-3">
            The purpose of private feedback is to foster transparency and mutual
            growth by providing constructive insights on the working
            relationship. It helps to understand areas for improvement, such as
            communication or resource provision, while acknowledging their
            strengths. This feedback aims to enhance collaboration and
            streamline future projects. Ultimately, it promotes a professional,
            respectful, and efficient partnership.
          </p>
        )}

        <BaseCard
          className={`  ${
            !previewFeedback && "p-4"
          } mt-5 shadow-md border border-zinc-100`}
          elevation={0}
        >
          {!previewFeedback && (
            <p className="text-sm font-normal ">
              How would you rate your client's overall approach to managing this
              work order?
            </p>
          )}
          <div className="flex items-center justify-between mx-24 my-4">
            <p>Unsatisfied</p>
            <BaseRating
              size="large"
              readOnly={previewFeedback ? true : false}
              rating={rating}
              onChange={(event, newValue) =>
                handleRatingChange(event, newValue)
              }
            />
            <p>Excellent</p>
          </div>

          {previewFeedback && comment && (
            <div className="mx-6 mb-4 bg-light rounded-md py-2 px-3">
              <p dangerouslySetInnerHTML={{ __html: comment }}></p>
            </div>
          )}
        </BaseCard>

        <BaseCard
          className="py-4 px-10 mt-7 shadow-md border border-zinc-100"
          elevation={0}
        >
          <p className="text-sm font-normal ">
            {previewFeedback
              ? "What did Buyer like most?"
              : "What did you like most?"}
          </p>
          <div className="grid grid-cols-3 gap-x-6 gap-y-3 mt-3">
            {mostLikedFeedbacks?.map((feedback, index) => (
              <FeedbackText
                previewFeedback={previewFeedback}
                text={feedback.text}
                isSelected={feedback.isSelected}
                key={index}
                onClick={handleMostLikedFeedbackClick}
              />
            ))}
          </div>
        </BaseCard>
        <BaseCard
          className="py-4 px-9 mt-7 shadow-md border border-zinc-100"
          elevation={0}
        >
          <p className="text-sm font-normal ">
            {previewFeedback
              ? "What Problem Buyer face?"
              : "What Problem you face?"}
          </p>
          <div className="grid grid-cols-3 gap-x-6 gap-y-3 mt-3">
            {problemFeedbacks?.map((feedback, index) => (
              <FeedbackText
                previewFeedback={previewFeedback}
                text={feedback.text}
                isSelected={feedback.isSelected}
                key={index}
                onClick={handleProblemFacedFeedbackClick}
              />
            ))}
          </div>
        </BaseCard>

        {!previewFeedback && (
          <div className="mt-6">
            <div className="flex items-center">
              <h6 className="font-normal mr-3">Add a comment</h6>
              <BaseSwitch
                secondary
                checked={isCommentAdded}
                onChange={() => {
                  setIsCommentAdded(!isCommentAdded);
                }}
              />
            </div>

            {isCommentAdded && (
              <BaseEditor
                readOnly={previewFeedback ? true : false}
                value={comment}
                className="mt-3 h-28"
                onChange={(body) => handleCommentChange(body)}
              />
            )}
          </div>
        )}
      </div>
      <div
        className="flex items-center justify-center bg-white py-6 px-5 mt-3"
        style={{
          boxShadow: "5px 0px 14px 5px rgba(0, 0, 0, 0.07)",
        }}
      >
        {!previewFeedback && (
          <BaseButton
            className="py-[5px] px-2 bg-[#E8E8E8] text-primary"
            fullWidth={false}
            onClick={handleCloseButton}
          >
            Cancel
          </BaseButton>
        )}
        {!previewFeedback && (
          <BaseButton
            /* disabled={isTaskUpdateLoading}
          isLoading={isTaskUpdateLoading} */
            className="py-[5px] px-4 ml-4"
            color="primary"
            fullWidth={false}
            onClick={handleSubmitButton}
          >
            Submit
          </BaseButton>
        )}

        {previewFeedback && (
          <BaseButton
            /* disabled={isTaskUpdateLoading}
          isLoading={isTaskUpdateLoading} */
            className="py-[5px] px-4 ml-4"
            color="primary"
            fullWidth={false}
            onClick={handleCloseButton}
          >
            Back
          </BaseButton>
        )}
      </div>
    </BaseModal>
  );
};

export default EngineerPrivateFeedbackModal;

function FeedbackText({ text, onClick, isSelected = true, previewFeedback }) {
  return (
    <div
      className={`flex items-center ${
        isSelected || previewFeedback
          ? "bg-primary/15	border border-indigo-300 opacity-1"
          : "bg-zinc-100 border"
      }  rounded py-1 px-2 cursor-pointer hover:bg-zinc-200`}
      onClick={() => onClick(text)}
    >
      {!previewFeedback && (
        <>
          {isSelected ? (
            <i className="fa-solid fa-circle-check text-primary  mr-2"></i>
          ) : (
            <i className="fa-solid fa-plus mr-2"></i>
          )}
        </>
      )}

      <p
        className={`text-xs ${
          (isSelected || previewFeedback) && "text-primary"
        }`}
      >
        {text}
      </p>
    </div>
  );
}
