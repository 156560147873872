import axios from "../../lib/axiosInstance";

export function createOffer(payload) {
  return axios.post("offers/engineer/request", payload).then((res) => res.data);
}

export function createCounterOffer(payload) {
  return axios
    .patch("offers/engineer/counter", payload)
    .then((res) => res.data);
}

export function declineOffer(payload) {
  return axios
    .post("offers/engineer/notInterested", payload)
    .then((res) => res.data);
}

export function fetchWorkOrderOffers(payload) {
  return axios.post(`offers/${payload.id}`, payload).then((res) => res.data);
}

export function assignWorkOrderToFreelancer(payload) {
  return axios.post(`offers/assign`, payload).then((res) => res.data);
}

export function updateWorkOrderStatus(payload) {
  return axios.post(`offers/status`, payload).then((res) => res.data);
}

export function routeOffer(payload) {
  return axios.post("offers/route", payload).then((res) => res.data);
}

export function deleteOffer(id) {
  return axios.delete(`offers/${id}`).then((res) => res.data);
}

export function withdrawOffer(id) {
  return axios.delete(`offers/engineer/withdraw/${id}`).then((res) => res.data);
}

export function fetchUserCounterOffer(payload) {
  return axios
    .post(`offers/engineer/workOrder/${payload?.id}`, payload)
    .then((res) => res.data);
}

export function deleteOfferExpense(id) {
  return axios.delete(`offers/engineer/expense/${id}`).then((res) => res.data);
}

export function acceptCounterOffer(offerId) {
  return axios.post(`offers/counter/accept/${offerId}`).then((res) => res.data);
}
