import React from "react";
import BaseCard from "../../../components/ui/BaseCard";

const Labour = ({ payment, paymentType, status }) => {
  return (
    <BaseCard className="pt-4 pb-6 px-56 rounded-lg" elevation={1}>
      {/* <div className="border-bottom mb-4 flex items-center justify-between pb-1">
        <h6 className="text-dark font-normal">
          Work Order Cost <span className="text-xs">(Estimated)</span>
        </h6>
        <h6 className="text-primary font-medium">
          ${payment?.totalWorkOrderCost?.toFixed(2)}
        </h6>
      </div> */}
      <div className="border-bottom mb-4 flex items-center justify-between pb-1">
        <h6 className="text-dark">Labour</h6>
        <h6 className="text-primary font-medium">
          ${payment?.totalLabourCost?.toFixed(2)}
        </h6>
      </div>

      <BaseCard className="mt-4" elevation={0}>
        {paymentType === "HOURLY" && (
          <div className="flex items-center mb-2">
            <p className="mr-2">Hourly rate</p>
            <div className="border-t border-dotted border-gray-500  flex-grow"></div>
            <p className="min-w-14 ml-5 font-normal">
              ${payment?.hourlyRate || 0}
            </p>
          </div>
        )}
        {paymentType === "HOURLY" && (
          <div className="flex items-center mb-2">
            <p className="mr-2">
              {payment?.hoursWorked > payment?.minimumHours
                ? "Payable Hours"
                : "Minimum Payable Hours"}
            </p>
            <div className="border-t border-dotted border-gray-500  flex-grow"></div>
            <p className="min-w-14 ml-5 ">
              {payment?.hoursWorked > payment?.minimumHours
                ? payment?.hoursWorked
                : payment?.minimumHours || 0}{" "}
              hrs
            </p>
          </div>
        )}

        {/* {paymentType === "HOURLY" && status === "WORKDONE" && (
          <div className="flex items-center mb-2 ">
            <p className="mr-2 text-primary font-medium	">
              Actual Working Hours
            </p>
            <div className="border-t border-dotted border-primary  flex-grow"></div>
            <p className="min-w-14 ml-5 text-primary font-medium">
              {status === "WORKDONE" ||
              status === "APPROVED" ||
              status === "INCOMPLETE"
                ? `${payment?.hoursWorked} hrs`
                : "Pending"}
            </p>
          </div>
        )} */}

        {payment?.expenses?.length > 0 && (
          <section className="mt-6">
            <div className="border-bottom mb-4 flex justify-between pb-3 mt-5">
              <h6 className="text-dark">Expenses</h6>
              <h6 className="text-primary font-medium">
                ${payment?.totalExpenseCost?.toFixed(2)}
              </h6>
            </div>
            {payment?.expenses.map((expense) => (
              <Expense
                key={expense.id}
                expenseType={expense.expenseType}
                description={expense.description}
                amount={expense.amount}
                addedBy={expense.addedBy.role.role}
              />
            ))}
          </section>
        )}
      </BaseCard>
    </BaseCard>
  );
};

function Expense({ expenseType, description, amount, addedBy }) {
  return (
    <div className="flex items-center mt-4 justify-between">
      <p className="font-normal mr-2 normal-case">
        <span className="">
          {expenseType === "TOOLS"
            ? "Tools"
            : expenseType === "MATERIAL"
            ? "Material"
            : expenseType === "TRAVEL"
            ? "Travel"
            : expenseType === "ALL"
            ? "All"
            : ""}
        </span>{" "}
        Expense ({description})
      </p>
      <div className="border-t border-dotted border-gray-500 flex-grow"></div>
      <div className="bg-[#F6F6F6] inline-flex items-center  ml-2 px-2 py-1 rounded-md whitespace-nowrap">
        <i className="fa-solid fa-check  mr-2"></i>
        <p className="font-normal">
          {addedBy === "CLIENT" ? "Buyer" : "Provider"}: ${amount}
        </p>
      </div>
    </div>
  );
}

export default Labour;
