import React from "react";
import dayjs from "dayjs";
import WorkOrderStatus from "../../components/WorkOrderStatus";

const ScheduleCard = ({
  schedule,
  assignedTo,
  status,
  tasks,
  timeLogs,
  subStatus,
}) => {
  const checkIn = tasks?.find((task) => task.taskType === "CHECK_IN");
  const checkOut = tasks?.find((task) => task.taskType === "CHECK_OUT");
  const onTheWay = tasks?.find((task) => task.taskType === "ON_THE_WAY");
  const runningLate = tasks?.find((task) => task.taskType === "RUNNING_LATE");

  const totalHours =
    timeLogs?.reduce((acc, timeLog) => acc + +timeLog.totalHours, 0) || null;

  return (
    <>
      {assignedTo && (status === "ASSIGNED" || status === "CONFIRM") ? (
        <>
          {subStatus === "ON_THE_WAY" ? (
            <OnTheWayCard task={onTheWay} timeZone={schedule?.timeZone} />
          ) : subStatus === "RUNNING_LATE" ? (
            <RunningLateCard task={runningLate} timeZone={schedule?.timeZone} />
          ) : (
            <ScheduleAssignedConfirmedCard
              status={subStatus}
              scheduleType={schedule?.scheduleType}
              startDate={schedule?.confirmedWorkOrderAt}
              startTime={schedule?.confirmedWorkOrderAt}
              timeZone={schedule?.timeZone}
            />
          )}
        </>
      ) : status === "INPROGRESS" ? (
        <CheckedInOutCard
          status={subStatus}
          startDateTime={checkIn?.completedTask.text}
          isCheckOut={checkOut.completedTask.isCompleted}
          endDateTime={checkOut.completedTask.text}
          timeZone={schedule.timeZone}
        />
      ) : status === "INCOMPLETE" ? (
        <ScheduleInCompleteCard title="Incomplete" totalHours={totalHours} />
      ) : (
        <SchedulePublishedCard
          title={
            schedule.scheduleType === "WORK_BETWEEN"
              ? "Work Between"
              : "Hard Time"
          }
          scheduleType={schedule.scheduleType}
          startDate={schedule?.orderStartDate}
          startTime={schedule?.orderStartTime}
          timeZone={schedule.timeZone}
        />
      )}
    </>
  );
};

export default ScheduleCard;

export function SchedulePublishedCard({
  title,
  startDate,
  startTime,
  timeZone,
}) {
  return (
    <div className="shadow-md rounded-md py-3 px-4 h-full min-h-24  w-full bg-white">
      <div className="flex">
        <i className="fa-regular fa-clock text-primary text-3xl"></i>
        <div className="ml-2 mt-2">
          <p className="font-normal text-dark">Schedule: {title}</p>
          <p className="mt-1 text-primary">
            {dayjs(startDate).format("DD/MM/YYYY")} 
            {dayjs(startTime).format("hh:mm A")} <br /> ({timeZone})
          </p>{" "}
        </div>
      </div>
    </div>
  );
}

function ScheduleInCompleteCard({ title, totalHours }) {
  return (
    <div className="shadow-md rounded-md py-3 px-4 h-full min-h-24  w-full">
      <div className="flex">
        <i className="fa-regular fa-clock text-primary text-3xl"></i>
        <div className="ml-2 mt-2">
          {title === "Incomplete" ? (
            <div className="flex">
              <p className="font-normal text-dark text-sm mr-1">Status: </p>
              <WorkOrderStatus
                status="INCOMPLETE"
                className="!py-[1.5px] !w-[80px] !text-[9px]"
              />
            </div>
          ) : (
            <p className={`font-normal text-dark `}>
              Status: <span>{title}</span>
            </p>
          )}
          <p className="mt-1 text-primary">Working Hours:</p>
          <p className="text-primary">{totalHours?.toFixed(2)} Hours</p>{" "}
        </div>
      </div>
    </div>
  );
}

function ScheduleAssignedConfirmedCard({
  status,
  startDate,
  startTime,
  timeZone,
}) {
  return (
    <div className="shadow-md rounded-md py-3 px-4 h-full min-h-24  w-full">
      <div className="flex">
        <i className="fa-solid fa-circle-info text-primary text-3xl"></i>
        {/* <i className="fa-regular fa-clock text-primary text-3xl"></i> */}
        <div className="ml-2 mt-2">
          {status === "NOT_CONFIRMED" && (
            <>
              <div className="flex">
                <p className="font-normal text-dark text-sm mr-1">Status: </p>
                <WorkOrderStatus
                  status="NOT_CONFIRMED"
                  className="!py-[1.5px] !w-[80px] !text-[9px]"
                />
              </div>
              <p className=" mt-1 text-primary">Confirm your task assignment</p>
            </>
          )}
          {status === "CONFIRMED" && (
            <>
              <div className="flex">
                <p className="font-normal text-dark text-sm mr-1">Status: </p>
                <WorkOrderStatus
                  status="CONFIRMED"
                  className="!py-[1.5px] !w-[80px] !text-[9px]"
                />
              </div>
              <p className=" mt-1 text-primary">
                Mark your work Order ready to go 24 hours before schedule time
              </p>
            </>
          )}

          {status === "READY_TO_GO" && (
            <>
              <div className="flex">
                <p className="font-normal text-dark text-sm mr-1">Status: </p>
                <WorkOrderStatus
                  status="READY_TO_GO"
                  className="!py-[1.5px] !w-[80px] !text-[9px]"
                />
              </div>
              <p className=" mt-1 text-primary">
                Marked at: {dayjs(startDate).format("DD/MM/YYYY")} 
                {dayjs(startTime).format("hh:mm A")} <br /> ({timeZone})
              </p>
            </>
          )}

          {status === "NOT_READY" && (
            <>
              <div className="flex">
                <p className="font-normal text-dark text-sm mr-1">Status: </p>
                <WorkOrderStatus
                  status="NO_READY"
                  className="!py-[1.5px] !w-[80px] !text-[9px]"
                />
              </div>
              <p className=" mt-1 text-primary">
                Mark your work Order ready to go 24 hours before schedule time
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

function CheckedInOutCard({ status, startDateTime, endDateTime, timeZone }) {
  return (
    <div className="shadow-md rounded-md py-3 px-4 h-full min-h-24  w-full">
      <div className="flex">
        <i className="fa-solid fa-circle-info text-primary text-3xl"></i>
        {/* <i className="fa-regular fa-clock text-primary text-3xl"></i> */}
        <div className="ml-2 mt-2">
          {status === "CHECK_OUT" && (
            <>
              <div className="flex">
                <p className="font-normal text-dark text-sm mr-1">Status: </p>
                <WorkOrderStatus
                  status="CHECK_OUT"
                  className="!py-[1.5px] !w-[80px] !text-[9px]"
                />
              </div>

              <p className="text-primary mt-1">
                Checked out at: <br />
                <span className="text-primary">
                  {dayjs(endDateTime).format("DD/MM/YYYY")} {""}
                </span>
                <span className="text-primary">
                  {dayjs(endDateTime).format("hh:mm A")}{" "}
                </span>
              </p>
              <p className="text-primary">({timeZone})</p>
            </>
          )}

          {status === "CHECK_IN" && (
            <>
              <div className="flex">
                <p className="font-normal text-dark text-sm mr-1">Status: </p>
                <WorkOrderStatus
                  status="CHECK_IN"
                  className="!py-[1.5px] !w-[80px] !text-[9px]"
                />
              </div>
              <p className="text-primary mt-1">
                at:
                <span className="text-primary ml-1">
                  {dayjs(startDateTime).format("DD/MM/YYYY")} {""}
                </span>
                <span className="text-primary">
                  {dayjs(startDateTime).format("hh:mm A")}{" "}
                </span>
              </p>
              <p className="text-primary">({timeZone})</p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

function OnTheWayCard({ task, timeZone }) {
  return (
    <div className="shadow-md rounded-md py-3 px-4 h-full min-h-24  w-full">
      <div className="flex">
        <i className="fa-solid fa-circle-info text-primary text-3xl"></i>

        <div className="ml-2 mt-2">
          <div className="flex">
            <p className="font-normal text-dark text-sm mr-1">Status: </p>
            <WorkOrderStatus
              status="ON_THE_WAY"
              className="!py-[1.5px] !w-[80px] !text-[9px]"
            />
          </div>
          <p className=" mt-1 text-primary">
            Marked at: {dayjs(task?.completedTask?.text).format("DD/MM/YYYY")} 
            {dayjs(task?.completedTask?.text).format("hh:mm A")} <br /> (
            {timeZone})
          </p>
        </div>
      </div>
    </div>
  );
}

function RunningLateCard({ task, timeZone }) {
  return (
    <div className="shadow-md rounded-md py-3 px-4 h-full min-h-24  w-full">
      <div className="flex">
        <i className="fa-solid fa-circle-info text-primary text-3xl"></i>

        <div className="ml-2 mt-2">
          <div className="flex">
            <p className="font-normal text-dark text-sm mr-1">Status: </p>
            <WorkOrderStatus
              status="RUNNING_LATE"
              className="!py-[1.5px] !w-[80px] !text-[9px]"
            />
          </div>
          <p className=" mt-1 text-primary">
            Marked at: {dayjs(task?.completedTask?.text).format("DD/MM/YYYY")} 
            {dayjs(task?.completedTask?.text).format("hh:mm A")} <br /> (
            {timeZone})
          </p>
        </div>
      </div>
    </div>
  );
}
