import React from "react";
import BaseModal from "../../../components/ui/BaseModal";
import BaseCard from "../../../components/ui/BaseCard";
import BaseButton from "../../../components/ui/BaseButton";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  fetchUserCounterOffer,
  withdrawOffer,
} from "../../../services/api/offers";
import { showToast } from "../../../store/toast/toastSlice";
import { useDispatch } from "react-redux";
import useAuth from "../../../hooks/useAuth";

const WithdrawCounterOfferModal = ({
  withdrawCounterOfferModalOpen,
  setWithdrawCounterOfferModalOpen,
  workOrderDetail,
  refetchUserAllowedAction,
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { auth } = useAuth();

  const {
    isLoading: isUserCounterOfferDataLoading,
    error: isUserCounterOfferDataError,
    data: userCounterOfferData,
  } = useQuery({
    queryKey: ["userCounterOffer"],
    queryFn: () => fetchUserCounterOffer({ id, userId: auth?.user?.id }),
    enabled: !!id,
  });

  const counterOffer = userCounterOfferData?.data;

  const {
    isPending: isWithdrawOfferLoading,
    status,
    error: isWithdrawOfferError,
    mutateAsync: withdrawOfferMutation,
  } = useMutation({
    mutationFn: withdrawOffer,
    onSuccess: (data) => {
      dispatch(
        showToast({
          message: "Counter Offer withdrawn Successfully",
          type: "success",
        })
      );
      refetchUserAllowedAction();
    },
  });

  const handleCounterOfferButton = async () => {
    try {
      await withdrawOfferMutation(workOrderDetail?.id);
      setWithdrawCounterOfferModalOpen(false);
    } catch (error) {
      dispatch(
        showToast({
          message: isWithdrawOfferError?.response?.data?.message,
          type: "error",
        })
      );
    }
  };

  return (
    <BaseModal
      style={{
        width: "1000px",
      }}
      open={withdrawCounterOfferModalOpen}
      close={() => setWithdrawCounterOfferModalOpen(false)}
    >
      <div className="container bg-light px-10">
        <h5 className="text-dark border-bottom mb-3">Withdraw Counter Offer</h5>

        <BaseCard className="p-4 mt-6" elevation={0}>
          <div className="flex justify-between border-bottom mb-2">
            <h6 className="text-dark">Schedule</h6>
            <div className="flex items-center bg-[#F2FAFF] py-1 px-4 rounded-md mb-2">
              <i className="fa-solid fa-circle-exclamation text-primary"></i>
              <p className=" ml-3 font-normal">Fixed Start</p>
            </div>
          </div>
          <div className="flex justify-between">
            <p>Start at a specific date and time</p>
            <p>
              {dayjs(workOrderDetail.orderStartDate).format("dddd, MM/DD/YYYY")}
               at {dayjs(workOrderDetail.orderStartDate).format("hh:mm A")}{" "}
              <br />({workOrderDetail?.address?.timeZone})
            </p>
          </div>
          <div>
            <h6>Labour</h6>
            <div className="flex items-center mt-4 mb-2">
              <p className="mr-5">Hourly Rate</p>
              <div className="border-t border-dotted border-gray-500  flex-grow"></div>

              <p className={`ml-5 font-normal `}>
                ${counterOffer?.hourlyRate?.toFixed(2)}
              </p>
            </div>
            <div className="flex items-center mb-2">
              <p className="mr-5">Minimum Payable Hours</p>
              <div className="border-t border-dotted border-gray-500  flex-grow"></div>
              <p className={`ml-5 font-normal`}>
                {counterOffer?.minimumHours} hrs
              </p>
            </div>
          </div>

          <div className="mt-6">
            <h6>Expenses</h6>
            {counterOffer?.expenses?.length > 0 &&
              counterOffer?.expenses?.map((expense, index) => (
                <div className="flex items-center mt-2 mb-3" key={index}>
                  <p className="mr-5">
                    {expense?.expenseType} ({expense?.description})
                  </p>
                  <div className="border-t border-dotted border-gray-500  flex-grow"></div>
                  <div className="flex items-center bg-light py-1 px-3 rounded-md ml-5">
                    <p className="ml-2 font-normal">
                      Provider:
                      <span className="ml-3">
                        {expense?.amount?.toFixed(2) || 0}
                      </span>
                    </p>
                  </div>
                </div>
              ))}
          </div>

          <div className="mt-6">
            <div className="flex justify-between bg-primary px-4 py-1 rounded-md">
              <h6 className="text-white">Total Estimate</h6>
              <h6 className="text-white">
                ${counterOffer?.totalWorkOrderCost?.toFixed(2)}
              </h6>
            </div>

            <p className="mt-3 text-primary pr-28">
              <span className="font-normal">Please not:</span> The estimated
              cost is the the minimum payable amount against this work order.
              However the actual pay will depend upon the time you spent to
              complete the job.
            </p>
          </div>
        </BaseCard>
      </div>

      <div
        className="flex justify-center py-8 shadow-xl"
        style={{
          boxShadow: "0px 0px 14px 5px rgba(0, 0, 0, 0.07)",
        }}
      >
        <BaseButton
          fullWidth={false}
          className="py-[4px] mr-8"
          variant="outlined"
          color="primary"
          onClick={() => setWithdrawCounterOfferModalOpen(false)}
        >
          Cancel
        </BaseButton>
        <BaseButton
          fullWidth={false}
          className="py-[5px]"
          color="primary"
          onClick={handleCounterOfferButton}
        >
          Withdraw
        </BaseButton>
      </div>
    </BaseModal>
  );
};

export default WithdrawCounterOfferModal;
