import React, { useEffect, useState } from "react";
import WorkJobDetailsHeader from "../../WorkJobDetails/WorkJobDetailsHeader";
import ActionBar from "../../WorkJobDetails/ActionBar";
import WorkJobTabs from "../../WorkJobDetails/WorkJobTabs";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import {
  fetchJobDetail,
  fetchUserAllowedAction,
} from "../../../services/api/workOrder";
import { useDispatch } from "react-redux";
import { setWorkOrderDetail } from "../../../store/workOrder/workOrderSlice";
import BaseLoader from "../../../components/ui/BaseLoader";

const JobDetails = () => {
  const { id } = useParams();
  const [userAllowedAction, setUserAllowedAction] = useState();
  const dispatch = useDispatch();
  const {
    isLoading: isjobDetailDataLoading,
    error: isjobDetailDataError,
    data: jobDetailData,
    // refetch: fetchJobDetail,
  } = useQuery({
    queryKey: ["jobDetail"],
    queryFn: () => fetchJobDetail(id),
    enabled: !!id,
  });

  const {
    isLoading: isAllowedActionLoading,
    error: isAllowedActionError,
    data: userAllowedActionData,
    refetch: refetchUserAllowedAction,
  } = useQuery({
    queryKey: ["fetchUserAllowedAction"],
    queryFn: () => fetchUserAllowedAction(id),
  });

  useEffect(() => {
    setUserAllowedAction(userAllowedActionData?.data);
  }, [userAllowedActionData]);

  // Update Redux state with job details
  useEffect(() => {
    if (jobDetailData?.data) {
      dispatch(setWorkOrderDetail(jobDetailData?.data));
    }
  }, [jobDetailData, dispatch]);

  return (
    <>
      <BaseLoader
        isLoading={isjobDetailDataLoading || isAllowedActionLoading}
      />
      <WorkJobDetailsHeader
        refetchUserAllowedAction={refetchUserAllowedAction}
        userAllowedAction={userAllowedAction}
      />
      <ActionBar
        refetchUserAllowedAction={refetchUserAllowedAction}
        userAllowedAction={userAllowedAction}
      />
      <div className="bg-light h-full py-2">
        <WorkJobTabs />
      </div>
    </>
  );
};

export default JobDetails;
