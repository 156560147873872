import * as React from "react";
import Rating from "@mui/material/Rating";

export default function BaseRating({
  readOnly = true,
  rating = 0,
  size = "small",
  style,
  onChange,
}) {
  return (
    <Rating
      readOnly={readOnly}
      value={rating}
      size={size}
      style={style}
      //   value={value}
      onChange={onChange}
    />
  );
}
